 <div *ngIf="loader" class="custom-loder d-flex justify-content-center align-items-center">
   <div class="loader"></div>
 </div>
<body class="app" *ngIf="!loading">
  <!-- select address panel start here -->
  <div
    class="offcanvas offcanvas-start select-address"
    tabindex="-1"
    id="offcanvasExample"
    aria-labelledby="offcanvasExampleLabel"
  >
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasExampleLabel">Select address</h5>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body">
      <div class="order-address-list d-flex flex-column">
        <ng-select
          *ngIf="
            orderData.type === orderType.partLoadOffice &&
            selectedAddressData?.type === 'pickup'
          "
          class=""
          aria-label="Default select example"
          placeholder="Select Address"
          [(ngModel)]="selectedAddressType"
          (change)="onAddressTypeChange($event)"
        >
          <ng-option value="office-address">BST office address</ng-option>
          <ng-option value="user-address">User Address</ng-option>
        </ng-select>
        <div
        class="order-address-item order-address"
        *ngFor="let address of deliveryAddresses"
        [ngClass]="{
          activeaddress:
          address.contactNumber ===
          selectedAddressData?.address?.contactNumber
        }"
        >
        <ul (click)="selectAddressData(address)">
          <li class="address">
              <!-- address -- {{ address | json }} -->
              <strong>{{ address.companyName }} </strong>
              {{ address.buildingName }}, {{ address.locality }},
              {{ address.city }}, India
              {{ address.pincode }}
            </li>
            <li>
              <strong>Contact person name</strong>{{ address.contactName }}
            </li>
            <li><strong>Mobile Number</strong>{{ address.contactNumber }}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="offcanvas-footer">
      <button
        type="button"
        class="btn btn-primary"
        (click)="saveAddressData(selectedAddressData)"
      >
        Save Address
      </button>
    </div>
  </div>
  <!-- select address panel end here -->
  <!--order header start here-->
  <div class="order-header d-flex justify-content-between align-items-center">
    <div class="back-btn">
      <a routerLink="/order">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="19"
          viewBox="0 0 18 19"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.55 18.05C17.85 17.75 18 17.4 18 17V2C18 1.6 17.85 1.25 17.55 0.950001C17.25 0.650002 16.9 0.5 16.5 0.5H1.5C1.1 0.5 0.75 0.650002 0.45 0.950001C0.15 1.25 0 1.6 0 2L0 17C0 17.4 0.15 17.75 0.45 18.05C0.75 18.35 1.1 18.5 1.5 18.5H16.5C16.9 18.5 17.25 18.35 17.55 18.05ZM9.0787 11.9933L7.51296 10.4276H12.0034C12.2594 10.4276 12.4711 10.3439 12.6385 10.1765C12.8059 10.0091 12.8896 9.79737 12.8896 9.54134C12.8896 9.28531 12.8059 9.07359 12.6385 8.90618C12.4711 8.73877 12.2594 8.65507 12.0034 8.65507H7.51296L9.0787 7.08933C9.25595 6.91208 9.34458 6.70528 9.34458 6.46894C9.34458 6.23261 9.25595 6.02581 9.0787 5.84856C8.90144 5.6713 8.69465 5.58268 8.45831 5.58268C8.22197 5.58268 8.01518 5.6713 7.83792 5.84856L4.76553 8.92095C4.58827 9.0982 4.49965 9.305 4.49965 9.54134C4.49965 9.77768 4.58827 9.98447 4.76553 10.1617L7.83792 13.2341C8.01518 13.4114 8.22197 13.5 8.45831 13.5C8.69465 13.5 8.90144 13.4114 9.0787 13.2341C9.25595 13.0569 9.34458 12.8501 9.34458 12.6137C9.34458 12.3774 9.25595 12.1706 9.0787 11.9933Z"
            fill="#004B64"
          ></path>
        </svg>
        Back</a
      >
    </div>
    <div class="order-heading">
      <h1>
        {{ orderData?.orderBy?.name | titlecase }}
      </h1>
      <ul>
        <li>
          Order ID : <strong>{{ orderData.orderNumber }}</strong>
        </li>
        <li>
          Status :
          <strong
            *ngIf="
              (orderData.isLoaded && orderData.state.code !== 'outForPickUp') ||
              !orderData.isLoaded
            "
            class="order"
            [ngClass]="orderData?.state?.code"
          >
            {{ getOrderStatus(orderData?.state?.code) | uppercase }}</strong
          >
          <strong
            *ngIf="
              orderData.isLoaded && orderData.state.code === 'outForPickUp'
            "
            class="order Paid"
            >Loaded</strong
          >
          <!-- <strong class="order" [ngClass]="orderData?.state?.code">
            {{ getOrderStatus(orderData?.state?.code) | uppercase }}</strong
          > -->
        </li>
        <li>{{ orderData.orderDate }}</li>
      </ul>
    </div>
    <div class="order-action">
      <a
        class="btn btn-danger"
        (click)="
          cancelOrder(orderCancelModalContent, orderCancelForm, 'cancelled')
        "
        *ngIf="editBtnDisplay.includes(orderData?.state?.code)"
        >Cancel Order</a
      >
      <a
        class="btn btn-primary"
        (click)="onSubmit()"
        *ngIf="updateBtnDisplay.includes(orderData.orderStatus)"
        >Update Order</a
      >
      <a
        class="btn btn-primary"
        (click)="orderStateSubmit(orderCancelForm, 'order-delivered')"
        *ngIf="
          deliverBtnDisplay.includes(orderData.orderStatus) &&
          !orderData?.orderDelivered
        "
        >Deliver Order</a
      >
      <a
        class="btn btn-primary"
        (click)="placeOrder()"
        *ngIf="orderData?.items.length && orderData.state?.code === orderStatus.adminPending"
        >Place Order</a
      >
    </div>
  </div>
  <!--order header end here-->

  <!--order detail start here -->
  <div class="order-details">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <!-- cancel order  start here-->
          <div
            class="card"
            *ngIf="
              orderData?.orderDetailId?.orderConfirmation?.action === 'reject'
            "
          >
            <!-- Reject order here-->
            <div class="card-body reject-order">
              <div
                class="card-header-custom d-flex justify-content-between align-items-start"
              >
                <div class="order-status-action">
                  <svg
                    width="50"
                    height="50"
                    viewBox="0 0 50 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1404_80)">
                      <path
                        d="M24.9998 4.16675C13.479 4.16675 4.1665 13.4792 4.1665 25.0001C4.1665 36.5209 13.479 45.8334 24.9998 45.8334C36.5207 45.8334 45.8332 36.5209 45.8332 25.0001C45.8332 13.4792 36.5207 4.16675 24.9998 4.16675ZM35.4165 32.4792L32.479 35.4167L24.9998 27.9376L17.5207 35.4167L14.5832 32.4792L22.0623 25.0001L14.5832 17.5209L17.5207 14.5834L24.9998 22.0626L32.479 14.5834L35.4165 17.5209L27.9373 25.0001L35.4165 32.4792Z"
                        fill="#E74C3C"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1404_80">
                        <rect width="50" height="50" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <h3>Order Rejected</h3>
                </div>
              </div>
              <h4>
                Reason :
                {{ orderData?.orderDetailId?.orderConfirmation?.reason }}
              </h4>
              <p>
                {{ orderData?.orderDetailId?.orderConfirmation?.comment }}
              </p>
            </div>
          </div>
          <!-- Reject order end here-->
          <!-- cancel order  start here-->
          <div
            class="card"
            *ngIf="orderData.state?.code === orderStatus.cancelled"
          >
            <!-- cancel order here-->
            <div class="card-body cancel-orders">
              <div
                class="card-header-custom d-flex justify-content-between align-items-start"
              >
                <div class="order-status-action">
                  <svg
                    width="50"
                    height="50"
                    viewBox="0 0 50 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1404_80)">
                      <path
                        d="M24.9998 4.16675C13.479 4.16675 4.1665 13.4792 4.1665 25.0001C4.1665 36.5209 13.479 45.8334 24.9998 45.8334C36.5207 45.8334 45.8332 36.5209 45.8332 25.0001C45.8332 13.4792 36.5207 4.16675 24.9998 4.16675ZM35.4165 32.4792L32.479 35.4167L24.9998 27.9376L17.5207 35.4167L14.5832 32.4792L22.0623 25.0001L14.5832 17.5209L17.5207 14.5834L24.9998 22.0626L32.479 14.5834L35.4165 17.5209L27.9373 25.0001L35.4165 32.4792Z"
                        fill="#E74C3C"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1404_80">
                        <rect width="50" height="50" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <div class="action-card d-flex flex-column">
                    <h3>Order Has Been Cancelled!</h3>
                    <p>{{ orderData.orderDate }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- cancel order end here-->

          <!-- Payment start here-->
          <div
            class="card"
            *ngIf="deliveredStatusDisplay.includes(orderData.state?.code)"
          >
            <!-- payement sucsess here-->
            <div
              class="card-body payment-view"
              *ngIf="orderData.orderPaid; else payForm"
            >
              <div
                class="card-header-custom d-flex justify-content-between align-items-start"
              >
                <div class="order-status-action">
                  <svg
                    width="50"
                    height="50"
                    viewBox="0 0 50 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.0622 40.1042L7.60384 38.8021C7.2219 38.7326 6.90072 38.533 6.6403 38.2031C6.37988 37.8733 6.2844 37.5 6.35384 37.0833L7.08301 30.8333L2.96842 26.0417C2.69065 25.7639 2.55176 25.4167 2.55176 25C2.55176 24.5833 2.69065 24.2361 2.96842 23.9583L7.08301 19.2188L6.35384 12.9688C6.2844 12.5521 6.37988 12.1788 6.6403 11.849C6.90072 11.5191 7.2219 11.3194 7.60384 11.25L14.0622 9.94792L17.2913 4.375C17.4997 4.02778 17.7948 3.78472 18.1768 3.64583C18.5587 3.50694 18.9406 3.52431 19.3226 3.69792L24.9997 6.35417L30.6768 3.69792C31.0587 3.52431 31.4493 3.48958 31.8486 3.59375C32.2479 3.69792 32.5344 3.94097 32.708 4.32292L35.9893 9.94792L42.3955 11.25C42.7775 11.3194 43.0986 11.5191 43.359 11.849C43.6195 12.1788 43.715 12.5521 43.6455 12.9688L42.9163 19.2188L47.0309 23.9583C47.3087 24.2361 47.4476 24.5833 47.4476 25C47.4476 25.4167 47.3087 25.7639 47.0309 26.0417L42.9163 30.8333L43.6455 37.0833C43.715 37.5 43.6195 37.8733 43.359 38.2031C43.0986 38.533 42.7775 38.7326 42.3955 38.8021L35.9893 40.1042L32.708 45.6771C32.5344 46.059 32.2479 46.3021 31.8486 46.4063C31.4493 46.5104 31.0587 46.4757 30.6768 46.3021L24.9997 43.6458L19.3226 46.3021C18.9406 46.4757 18.5587 46.4931 18.1768 46.3542C17.7948 46.2153 17.4997 45.9722 17.2913 45.625L14.0622 40.1042ZM21.6663 30.8333C21.9788 31.1458 22.3434 31.3021 22.7601 31.3021C23.1768 31.3021 23.5413 31.1458 23.8538 30.8333L33.4893 21.3021C33.767 21.0243 33.8972 20.6684 33.8799 20.2344C33.8625 19.8003 33.6976 19.4271 33.3851 19.1146C33.0726 18.8021 32.6993 18.6545 32.2653 18.6719C31.8313 18.6892 31.4406 18.8542 31.0934 19.1667L22.7601 27.4479L18.958 23.4375C18.6455 23.0903 18.2549 22.9253 17.7861 22.9427C17.3174 22.9601 16.9268 23.1424 16.6143 23.4896C16.3018 23.8368 16.1368 24.2361 16.1195 24.6875C16.1021 25.1389 16.267 25.5208 16.6143 25.8333L21.6663 30.8333Z"
                      fill="#42887D"
                    />
                  </svg>
                  <div class="action-card d-flex flex-column">
                    <h3>Payment Received Successfully!</h3>
                    <p>
                      {{ orderData.paymentDetail.paymentType | uppercase }} |
                      ID: {{ orderData.paymentDetail.transactionId }} |
                      {{ getDateTimeofStatus(orderStatus.paid) }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <ng-template #payForm>
              <div class="card-body Payment">
                <div
                  class="card-header-custom d-flex justify-content-between align-items-start"
                >
                  <div class="card-heading-details">
                    <h3>Payment Pending (₹{{ calculateTotalEstimate() }})</h3>
                  </div>
                </div>
                <form
                  [formGroup]="orderPaymentForm"
                  (ngSubmit)="orderStateSubmit(orderPaymentForm, 'delivered')"
                >
                  <div class="order-form">
                    <div class="mb-3 form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="exampleCheck1"
                        formControlName="orderPaid"
                      />
                      <label class="form-check-label" for="exampleCheck1"
                        >Payment Complete</label
                      >
                    </div>
                    <div
                      formGroupName="paymentDetail"
                      *ngIf="orderPaymentForm.value.orderPaid"
                    >
                      <div class="row">
                        <div class="mb-3 col-lg-3">
                          <label class="form-label">Select payment type</label>
                          <ng-select
                            class=""
                            aria-label="Default select example"
                            placeholder="Select payment mode"
                            formControlName="paymentType"
                          >
                            <ng-option value="online">Online </ng-option>
                            <ng-option value="cheque">Cheque</ng-option>
                          </ng-select>
                        </div>
                        <div class="mb-3 col-lg-3">
                          <label class="form-label">Transaction Id</label>
                          <input
                            type="text"
                            class="form-control"
                            formControlName="transactionId"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12 mb-3">
                      <button
                      *ngIf="orderData.state.code === 'delivered'"
                        type="submit"
                        class="btn btn-primary payment-recived-btn"
                        [disabled]="loader || ((!orderPaymentForm.value.orderPaid 
                        || !orderPaymentForm.get('paymentDetail.paymentType')?.value 
                        || !orderPaymentForm.get('paymentDetail.transactionId')?.value))"
                      >
                        <ng-container *ngIf="!loader; else showLoader">
                            Paid
                        </ng-container>
                      </button>
                    
                      <button
                        *ngIf="orderData.state.code === 'invoiceUploaded'"
                        type="submit"
                        class="btn btn-primary payment-recived-btn"
                        [disabled]="loader || ((orderPaymentForm.value.orderPaid  && (
                          !orderPaymentForm.get('paymentDetail.paymentType')?.value 
                          || !orderPaymentForm.get('paymentDetail.transactionId')?.value
                        )))"
                      >
                        <ng-container *ngIf="!loader; else showLoader">
                          Order delivered
                        </ng-container>
                      </button>
                      
                      <ng-template #showLoader>
                        <span
                          class="spinner-border spinner-border-sm"
                          aria-hidden="true"
                        ></span>
                        <span role="status">Loading...</span>
                      </ng-template>
                    </div>


                    <!-- <div class="col-lg-12 mb-3">
                      <button
                        type="submit"
                        class="btn btn-primary payment-recived-btn"
                        [disabled]="loader  || (!orderPaymentForm.value.orderPaid 
                        || !orderPaymentForm.get('paymentDetail.paymentType')?.value 
                        || !orderPaymentForm.get('paymentDetail.transactionId')?.value)"
                      >
                        <ng-container *ngIf="!loader; else showLoader">
                          <ng-container
                            *ngIf="orderData.state.code === 'delivered'"
                          >
                            Paid
                          </ng-container>
                          <ng-container
                            *ngIf="orderData.state.code === 'invoiceUploaded'"
                          >
                            Order delivered
                          </ng-container>
                        </ng-container>
                        <ng-template #showLoader>
                          <span
                            class="spinner-border spinner-border-sm"
                            aria-hidden="true"
                          ></span>
                          <span role="status">Loading...</span>
                        </ng-template>
                      </button>
                    </div> -->
                  </div>
                </form>
              </div>
            </ng-template>
          </div>
          <!-- Payment end here-->
          <!--when status is delivered-->
          <!-- order deliver success start here-->
          <div class="card" *ngIf="orderData.orderDelivered">
            <!-- order sucsess here-->
            <div class="card-body payment-view">
              <div
                class="card-header-custom d-flex justify-content-between align-items-start"
              >
                <div class="order-status-action">
                  <svg
                    width="50"
                    height="50"
                    viewBox="0 0 50 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.0622 40.1042L7.60384 38.8021C7.2219 38.7326 6.90072 38.533 6.6403 38.2031C6.37988 37.8733 6.2844 37.5 6.35384 37.0833L7.08301 30.8333L2.96842 26.0417C2.69065 25.7639 2.55176 25.4167 2.55176 25C2.55176 24.5833 2.69065 24.2361 2.96842 23.9583L7.08301 19.2188L6.35384 12.9688C6.2844 12.5521 6.37988 12.1788 6.6403 11.849C6.90072 11.5191 7.2219 11.3194 7.60384 11.25L14.0622 9.94792L17.2913 4.375C17.4997 4.02778 17.7948 3.78472 18.1768 3.64583C18.5587 3.50694 18.9406 3.52431 19.3226 3.69792L24.9997 6.35417L30.6768 3.69792C31.0587 3.52431 31.4493 3.48958 31.8486 3.59375C32.2479 3.69792 32.5344 3.94097 32.708 4.32292L35.9893 9.94792L42.3955 11.25C42.7775 11.3194 43.0986 11.5191 43.359 11.849C43.6195 12.1788 43.715 12.5521 43.6455 12.9688L42.9163 19.2188L47.0309 23.9583C47.3087 24.2361 47.4476 24.5833 47.4476 25C47.4476 25.4167 47.3087 25.7639 47.0309 26.0417L42.9163 30.8333L43.6455 37.0833C43.715 37.5 43.6195 37.8733 43.359 38.2031C43.0986 38.533 42.7775 38.7326 42.3955 38.8021L35.9893 40.1042L32.708 45.6771C32.5344 46.059 32.2479 46.3021 31.8486 46.4063C31.4493 46.5104 31.0587 46.4757 30.6768 46.3021L24.9997 43.6458L19.3226 46.3021C18.9406 46.4757 18.5587 46.4931 18.1768 46.3542C17.7948 46.2153 17.4997 45.9722 17.2913 45.625L14.0622 40.1042ZM21.6663 30.8333C21.9788 31.1458 22.3434 31.3021 22.7601 31.3021C23.1768 31.3021 23.5413 31.1458 23.8538 30.8333L33.4893 21.3021C33.767 21.0243 33.8972 20.6684 33.8799 20.2344C33.8625 19.8003 33.6976 19.4271 33.3851 19.1146C33.0726 18.8021 32.6993 18.6545 32.2653 18.6719C31.8313 18.6892 31.4406 18.8542 31.0934 19.1667L22.7601 27.4479L18.958 23.4375C18.6455 23.0903 18.2549 22.9253 17.7861 22.9427C17.3174 22.9601 16.9268 23.1424 16.6143 23.4896C16.3018 23.8368 16.1368 24.2361 16.1195 24.6875C16.1021 25.1389 16.267 25.5208 16.6143 25.8333L21.6663 30.8333Z"
                      fill="#025C54"
                    />
                  </svg>
                  <div class="action-card d-flex flex-column">
                    <h3>Order Delivered Successfully!</h3>
                    <p>{{ getDateTimeofStatus(orderStatus.delivered) }}</p>
                    <!-- <div class="attachment" *ngIf="orderData.invoiceDetail">
                      <a
                        (click)="
                          fileDownload(
                            orderData.invoiceDetail.path,
                            orderData.invoiceDetail.name
                          )
                        "
                        >{{ orderData.invoiceDetail.name
                        }}<svg
                          width="25"
                          height="25"
                          viewBox="0 0 25 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.729 20.8334C5.31234 20.8334 4.94775 20.6772 4.63525 20.3647C4.32275 20.0522 4.1665 19.6876 4.1665 19.2709V15.547H5.729V19.2709H19.2707V15.547H20.8332V19.2709C20.8332 19.6876 20.6769 20.0522 20.3644 20.3647C20.0519 20.6772 19.6873 20.8334 19.2707 20.8334H5.729ZM12.4998 16.849L7.4738 11.823L8.59359 10.7032L11.7186 13.8282V4.16675H13.2811V13.8282L16.4061 10.7032L17.5259 11.823L12.4998 16.849Z"
                            fill="#025C54"
                          />
                        </svg>
                      </a>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- order success end here-->

          <!--when status is dispatched-->
          <!-- invoice start here-->
          <div
            class="card"
            *ngIf="invoiceBlockDisplay.includes(orderData.state?.code)"
          >
            <!-- invoice view start here-->
            <div class="card-body invoic-view">
              <div
                class="card-header-custom d-flex justify-content-between align-items-start"
              >
              <div class="card-heading-details"  *ngIf="!orderData.invoiceDetail">
                <h3>Invoice Upload</h3>
              </div>
                <div
                  class="order-status-action"
                  *ngIf="orderData.invoiceDetail"
                >
                  <svg
                    *ngIf="!editDispatchOrderMode"
                    width="50"
                    height="50"
                    viewBox="0 0 50 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.0622 40.1042L7.60384 38.8021C7.2219 38.7326 6.90072 38.533 6.6403 38.2031C6.37988 37.8733 6.2844 37.5 6.35384 37.0833L7.08301 30.8333L2.96842 26.0417C2.69065 25.7639 2.55176 25.4167 2.55176 25C2.55176 24.5833 2.69065 24.2361 2.96842 23.9583L7.08301 19.2188L6.35384 12.9688C6.2844 12.5521 6.37988 12.1788 6.6403 11.849C6.90072 11.5191 7.2219 11.3194 7.60384 11.25L14.0622 9.94792L17.2913 4.375C17.4997 4.02778 17.7948 3.78472 18.1768 3.64583C18.5587 3.50694 18.9406 3.52431 19.3226 3.69792L24.9997 6.35417L30.6768 3.69792C31.0587 3.52431 31.4493 3.48958 31.8486 3.59375C32.2479 3.69792 32.5344 3.94097 32.708 4.32292L35.9893 9.94792L42.3955 11.25C42.7775 11.3194 43.0986 11.5191 43.359 11.849C43.6195 12.1788 43.715 12.5521 43.6455 12.9688L42.9163 19.2188L47.0309 23.9583C47.3087 24.2361 47.4476 24.5833 47.4476 25C47.4476 25.4167 47.3087 25.7639 47.0309 26.0417L42.9163 30.8333L43.6455 37.0833C43.715 37.5 43.6195 37.8733 43.359 38.2031C43.0986 38.533 42.7775 38.7326 42.3955 38.8021L35.9893 40.1042L32.708 45.6771C32.5344 46.059 32.2479 46.3021 31.8486 46.4063C31.4493 46.5104 31.0587 46.4757 30.6768 46.3021L24.9997 43.6458L19.3226 46.3021C18.9406 46.4757 18.5587 46.4931 18.1768 46.3542C17.7948 46.2153 17.4997 45.9722 17.2913 45.625L14.0622 40.1042ZM21.6663 30.8333C21.9788 31.1458 22.3434 31.3021 22.7601 31.3021C23.1768 31.3021 23.5413 31.1458 23.8538 30.8333L33.4893 21.3021C33.767 21.0243 33.8972 20.6684 33.8799 20.2344C33.8625 19.8003 33.6976 19.4271 33.3851 19.1146C33.0726 18.8021 32.6993 18.6545 32.2653 18.6719C31.8313 18.6892 31.4406 18.8542 31.0934 19.1667L22.7601 27.4479L18.958 23.4375C18.6455 23.0903 18.2549 22.9253 17.7861 22.9427C17.3174 22.9601 16.9268 23.1424 16.6143 23.4896C16.3018 23.8368 16.1368 24.2361 16.1195 24.6875C16.1021 25.1389 16.267 25.5208 16.6143 25.8333L21.6663 30.8333Z"
                      fill="#025C54"
                    />
                  </svg>
                  <div class="card-heading-details">
                    <h3>Order Invoice upload Successfully !</h3>
                    <p>
                      {{ getDateTimeofStatus(orderStatus.invoiceUploaded) }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="invoice-detail-list" *ngIf="orderData.invoiceDetail">
                <div class="attachment">
                  <a
                    (click)="
                      fileDownload(
                        orderData?.invoiceDetail.path,
                        orderData?.invoiceDetail.name
                      )
                    "
                    >{{ orderData?.invoiceDetail.name
                    }}<svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.729 20.8334C5.31234 20.8334 4.94775 20.6772 4.63525 20.3647C4.32275 20.0522 4.1665 19.6876 4.1665 19.2709V15.547H5.729V19.2709H19.2707V15.547H20.8332V19.2709C20.8332 19.6876 20.6769 20.0522 20.3644 20.3647C20.0519 20.6772 19.6873 20.8334 19.2707 20.8334H5.729ZM12.4998 16.849L7.4738 11.823L8.59359 10.7032L11.7186 13.8282V4.16675H13.2811V13.8282L16.4061 10.7032L17.5259 11.823L12.4998 16.849Z"
                        fill="#025C54"
                      />
                    </svg>
                  </a>
                </div>
              </div>

              <form
                *ngIf="!orderData.invoiceDetail"
                [formGroup]="orderInvoiceForm"
                (ngSubmit)="
                  orderStateSubmit(orderInvoiceForm, 'invoiceUploaded')
                "
              >
                <div class="order-form">
                  <div class="mb-3 col-lg-6">
                    <label for="invoice" class="form-label"
                      >Attach Invoice *</label
                    >
                    <input
                      type="file"
                      class="form-control"
                      id="invoice"
                      aria-describedby="emailHelp"
                      (change)="onFileChange($event, 'invoice')"
                    />
                    <bst-frontend-form-field-error-display
                      errorMsg="{{ displayErrMessage?.file }}"
                    ></bst-frontend-form-field-error-display>
                  </div>
                  <div class="col-lg-12 mb-3">
                    <button
                      type="submit"
                      class="btn btn-primary estimate-add"
                      [disabled]="loader"
                    >
                      <ng-container *ngIf="!loader; else showLoader">
                        Add Invoice
                      </ng-container>
                      <ng-template #showLoader>
                        <span
                          class="spinner-border spinner-border-sm"
                          aria-hidden="true"
                        ></span>
                        <span role="status">Loading...</span>
                      </ng-template>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!-- invoice end here-->

          <!-- Order Dispatch start here-->
          <div
            class="card"
            *ngIf="orderData.isAllPickedUp && orderData.isLoaded"
          >
            <!-- Order Dispatch view start here-->
            <div class="card-body invoic-view">
              <div
                *ngIf="
                  orderDispatchBlockDisplay.includes(orderData.state?.code)
                "
                class="card-header-custom d-flex justify-content-between align-items-start"
              >
                <div class="order-status-action">
                  <svg
                    *ngIf="!editDispatchOrderMode"
                    width="50"
                    height="50"
                    viewBox="0 0 50 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.0622 40.1042L7.60384 38.8021C7.2219 38.7326 6.90072 38.533 6.6403 38.2031C6.37988 37.8733 6.2844 37.5 6.35384 37.0833L7.08301 30.8333L2.96842 26.0417C2.69065 25.7639 2.55176 25.4167 2.55176 25C2.55176 24.5833 2.69065 24.2361 2.96842 23.9583L7.08301 19.2188L6.35384 12.9688C6.2844 12.5521 6.37988 12.1788 6.6403 11.849C6.90072 11.5191 7.2219 11.3194 7.60384 11.25L14.0622 9.94792L17.2913 4.375C17.4997 4.02778 17.7948 3.78472 18.1768 3.64583C18.5587 3.50694 18.9406 3.52431 19.3226 3.69792L24.9997 6.35417L30.6768 3.69792C31.0587 3.52431 31.4493 3.48958 31.8486 3.59375C32.2479 3.69792 32.5344 3.94097 32.708 4.32292L35.9893 9.94792L42.3955 11.25C42.7775 11.3194 43.0986 11.5191 43.359 11.849C43.6195 12.1788 43.715 12.5521 43.6455 12.9688L42.9163 19.2188L47.0309 23.9583C47.3087 24.2361 47.4476 24.5833 47.4476 25C47.4476 25.4167 47.3087 25.7639 47.0309 26.0417L42.9163 30.8333L43.6455 37.0833C43.715 37.5 43.6195 37.8733 43.359 38.2031C43.0986 38.533 42.7775 38.7326 42.3955 38.8021L35.9893 40.1042L32.708 45.6771C32.5344 46.059 32.2479 46.3021 31.8486 46.4063C31.4493 46.5104 31.0587 46.4757 30.6768 46.3021L24.9997 43.6458L19.3226 46.3021C18.9406 46.4757 18.5587 46.4931 18.1768 46.3542C17.7948 46.2153 17.4997 45.9722 17.2913 45.625L14.0622 40.1042ZM21.6663 30.8333C21.9788 31.1458 22.3434 31.3021 22.7601 31.3021C23.1768 31.3021 23.5413 31.1458 23.8538 30.8333L33.4893 21.3021C33.767 21.0243 33.8972 20.6684 33.8799 20.2344C33.8625 19.8003 33.6976 19.4271 33.3851 19.1146C33.0726 18.8021 32.6993 18.6545 32.2653 18.6719C31.8313 18.6892 31.4406 18.8542 31.0934 19.1667L22.7601 27.4479L18.958 23.4375C18.6455 23.0903 18.2549 22.9253 17.7861 22.9427C17.3174 22.9601 16.9268 23.1424 16.6143 23.4896C16.3018 23.8368 16.1368 24.2361 16.1195 24.6875C16.1021 25.1389 16.267 25.5208 16.6143 25.8333L21.6663 30.8333Z"
                      fill="#025C54"
                    />
                  </svg>

                  <div
                    *ngIf="!editDispatchOrderMode"
                    class="card-heading-details"
                  >
                    <h3>Order has been dispatch</h3>
                    <p>{{ getDateTimeofStatus(orderStatus.dispatched) }}</p>
                  </div>
                </div>
                <a
                  class="btn btn-primary truck-detail-add"
                  (click)="editDispatchOrderMode = true"
                  *ngIf="
                    editBtnDisplay.includes(orderData.state?.code) &&
                    orderData.truckDetail?.truckNo &&
                    !editDispatchOrderMode
                  "
                  >Edit</a
                >
              </div>
                    <div
                      class="truck-detail-list"
                      *ngIf="!editDispatchOrderMode && orderData?.dispatchDetail?.attachment"
                    >
                      <div
                        class="attachment"
                        *ngIf="
                          dispatchOrderForm.value.dispatchDetail.attachment
                        "
                      >
                        <a
                          (click)="
                            fileDownload(
                              orderData?.dispatchDetail?.attachment.path,
                              orderData?.dispatchDetail?.attachment.name
                            )
                          "
                          >{{ orderData?.dispatchDetail?.attachment.name
                          }}<svg
                            width="25"
                            height="25"
                            viewBox="0 0 25 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.729 20.8334C5.31234 20.8334 4.94775 20.6772 4.63525 20.3647C4.32275 20.0522 4.1665 19.6876 4.1665 19.2709V15.547H5.729V19.2709H19.2707V15.547H20.8332V19.2709C20.8332 19.6876 20.6769 20.0522 20.3644 20.3647C20.0519 20.6772 19.6873 20.8334 19.2707 20.8334H5.729ZM12.4998 16.849L7.4738 11.823L8.59359 10.7032L11.7186 13.8282V4.16675H13.2811V13.8282L16.4061 10.7032L17.5259 11.823L12.4998 16.849Z"
                              fill="#025C54"
                            />
                          </svg>
                        </a>
                      </div>
                      <ul>
                        <li class="notes">
                          <strong>Notes : </strong
                          >{{ orderData?.dispatchDetail?.notes }}
                        </li>
                      </ul>
                    </div>
              <form
                *ngIf="
                  orderData.state.code === 'outForPickUp' ||
                  editDispatchOrderMode
                "
                [formGroup]="dispatchOrderForm"
                (ngSubmit)="orderStateSubmit(dispatchOrderForm, 'dispatched')"
              >
                <div class="order-form" formGroupName="dispatchDetail">
                  <div class="mb-3 col-lg-6">
                    <div class="card-header-custom d-flex justify-content-between align-items-start">
                      <div class="card-heading-details">
                        <h3>Order has been dispatch</h3>
                      </div>
                    </div>
                  </div>
                  <div class="mb-3 col-lg-6">
                    <label for="invoice" class="form-label"
                      >Attach Weight Slip (JPG, PNG, PDF) *</label
                    >
                    <input
                      type="file"
                      class="form-control"
                      id="dispatchOrder"
                      aria-describedby="emailHelp"
                      (change)="onFileChange($event, 'dispatchOrder')"
                    />
                    <bst-frontend-form-field-error-display
                      errorMsg="{{ displayErrMessage?.file }}"
                    ></bst-frontend-form-field-error-display>
                  </div>

                  <div class="mb-3 col-lg-12">
                    <label for="loaded-truck-notes" class="form-label"
                      >Notes</label
                    >
                    <textarea
                      type="text"
                      class="form-control"
                      id="loaded-truck-notes"
                      placeholder="Enter notes"
                      aria-describedby="emailHelp"
                      formControlName="notes"
                    ></textarea>
                  </div>

                  <div class="col-lg-12 mb-3">
                    <button
                      type="submit"
                      class="btn btn-primary estimate-add"
                      [disabled]="loader"
                    >
                      <ng-container *ngIf="!loader; else showLoader">
                        Order dispatch
                      </ng-container>
                      <ng-template #showLoader>
                        <span
                          class="spinner-border spinner-border-sm"
                          aria-hidden="true"
                        ></span>
                        <span role="status">Loading...</span>
                      </ng-template>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!-- Order Dispatch end here-->

          <!-- order loaded success start here-->
          <div
            class="card"
            *ngIf="
              orderData.isAllPickedUp &&
              loadedStatusDisplay.includes(orderData.state?.code)
            "
          >
            <div class="card-body payment-view">
              <div
                class="card-header-custom d-flex justify-content-between align-items-start"
              >
                <div class="order-status-action">
                  <svg
                    *ngIf="orderData.isLoaded"
                    width="50"
                    height="50"
                    viewBox="0 0 50 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.0622 40.1042L7.60384 38.8021C7.2219 38.7326 6.90072 38.533 6.6403 38.2031C6.37988 37.8733 6.2844 37.5 6.35384 37.0833L7.08301 30.8333L2.96842 26.0417C2.69065 25.7639 2.55176 25.4167 2.55176 25C2.55176 24.5833 2.69065 24.2361 2.96842 23.9583L7.08301 19.2188L6.35384 12.9688C6.2844 12.5521 6.37988 12.1788 6.6403 11.849C6.90072 11.5191 7.2219 11.3194 7.60384 11.25L14.0622 9.94792L17.2913 4.375C17.4997 4.02778 17.7948 3.78472 18.1768 3.64583C18.5587 3.50694 18.9406 3.52431 19.3226 3.69792L24.9997 6.35417L30.6768 3.69792C31.0587 3.52431 31.4493 3.48958 31.8486 3.59375C32.2479 3.69792 32.5344 3.94097 32.708 4.32292L35.9893 9.94792L42.3955 11.25C42.7775 11.3194 43.0986 11.5191 43.359 11.849C43.6195 12.1788 43.715 12.5521 43.6455 12.9688L42.9163 19.2188L47.0309 23.9583C47.3087 24.2361 47.4476 24.5833 47.4476 25C47.4476 25.4167 47.3087 25.7639 47.0309 26.0417L42.9163 30.8333L43.6455 37.0833C43.715 37.5 43.6195 37.8733 43.359 38.2031C43.0986 38.533 42.7775 38.7326 42.3955 38.8021L35.9893 40.1042L32.708 45.6771C32.5344 46.059 32.2479 46.3021 31.8486 46.4063C31.4493 46.5104 31.0587 46.4757 30.6768 46.3021L24.9997 43.6458L19.3226 46.3021C18.9406 46.4757 18.5587 46.4931 18.1768 46.3542C17.7948 46.2153 17.4997 45.9722 17.2913 45.625L14.0622 40.1042ZM21.6663 30.8333C21.9788 31.1458 22.3434 31.3021 22.7601 31.3021C23.1768 31.3021 23.5413 31.1458 23.8538 30.8333L33.4893 21.3021C33.767 21.0243 33.8972 20.6684 33.8799 20.2344C33.8625 19.8003 33.6976 19.4271 33.3851 19.1146C33.0726 18.8021 32.6993 18.6545 32.2653 18.6719C31.8313 18.6892 31.4406 18.8542 31.0934 19.1667L22.7601 27.4479L18.958 23.4375C18.6455 23.0903 18.2549 22.9253 17.7861 22.9427C17.3174 22.9601 16.9268 23.1424 16.6143 23.4896C16.3018 23.8368 16.1368 24.2361 16.1195 24.6875C16.1021 25.1389 16.267 25.5208 16.6143 25.8333L21.6663 30.8333Z"
                      fill="#025C54"
                    />
                  </svg>
                  <ng-container *ngIf="orderData.isLoaded; else dispatched">
                    <div class="action-card d-flex flex-column">
                      <h3>Order has been loaded</h3>
                      <p *ngIf="orderStatus.loaded">
                        {{ getDateTimeofStatus(orderStatus.loaded) }}
                      </p>
                    </div>
                  </ng-container>
                  <ng-template #dispatched>
                    <div class="action-card d-flex flex-column">
                      <h3>Order has been Loded</h3>
                      <button
                        class="btn btn-primary"
                        (click)="updateOrderLoaded()"
                        [disabled]="loader"
                      >
                        <ng-container *ngIf="!loader; else showLoader">
                          Order Loded
                        </ng-container>
                        <ng-template #showLoader>
                          <span
                            class="spinner-border spinner-border-sm"
                            aria-hidden="true"
                          ></span>
                          <span role="status">Loading...</span>
                        </ng-template>
                      </button>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
          <!--  order loaded end here-->
          <!-- Vehicle there start here-->
          <!--when status is truck-assigned-->
          <ng-container
            *ngIf="truckAssignedStatusDisplay.includes(orderData.state?.code)"
          >
            <form [formGroup]="assignVehicleForm">
              <ng-container formArrayName="pickUpVehicle">
                <div
                  class="card veichle-there"
                  *ngFor="let pickup of pickUpVehicle.controls; let i = index"
                  [formGroupName]="i"
                >
                  <div class="card-body">
                    <!--Vehicle there edit view start here -->
                    <div
                      class="card-header-custom d-flex justify-content-between align-items-start"
                    >
                      <div class="card-heading-details">
                        <p>Vehicle reached your pickup location</p>
                        <h3>{{ pickup.value.companyName }}</h3>
                      </div>
                    </div>
                    <ng-container *ngIf="!pickup.value.isEdit; else noDetail">
                      <!--Vehicle there edit view start here -->
                      <div class="truck-detail-list customer-details">
                        <ul>
                          <li>
                            <strong>Vehicle No</strong
                            >{{ pickup.value.vehicleNumber }}
                          </li>
                          <li>
                            <strong>Date & Time : </strong
                            >{{ formatDate(pickup.value.arrivalTimes) }}
                          </li>
                        </ul>
                        <!-- <a
                        class="btn btn-primary truck-detail-add"
                        (click)="editPickUpDetails(pickup)"
                        *ngIf="!pickup.editMode"
                        >Edit Details</a
                      > -->
                      </div>
                      <!--vehicle there end view start here -->
                    </ng-container>
                    <ng-template #noDetail>
                      <div class="order-form">
                        <div class="row">
                          <div class="mb-3 col-lg-3">
                            <label class="form-label">Select vehicle</label>
                            <ng-select
                              class=""
                              placeholder="Select Vehicle"
                              formControlName="vehicleType"
                              (change)="showVehicleNumber(i)"
                            >
                              <ng-option value="main" selected
                                >Main Vehicle
                              </ng-option>
                              <ng-option value="other">OTHER</ng-option>
                            </ng-select>
                          </div>
                          <div
                            class="mb-3 col-lg-3"
                            *ngIf="pickup.value.showVehicleNumber"
                          >
                            <label class="form-label">Vehicle No</label>
                            <input
                              type="text"
                              class="form-control"
                              formControlName="vehicleNumber"
                            />
                          </div>
                          <div class="col-lg-12">
                            <button
                              type="button"
                              (click)="assignVehicle(i)"
                              class="btn btn-primary"
                              [disabled]="
                                (loader && vehicleAssignIndex === i) ||
                                (pickup.value.showVehicleNumber &&
                                  !pickup.value.vehicleNumber) ||
                                !pickup.value.vehicleType
                              "
                            >
                              <ng-container
                                *ngIf="
                                  loader && vehicleAssignIndex === i;
                                  else showLoader
                                "
                              >
                                <span
                                  class="spinner-border spinner-border-sm"
                                  aria-hidden="true"
                                ></span>
                                <span role="status">Loading...</span>
                              </ng-container>
                              <ng-template #showLoader>
                                Vehicle there
                              </ng-template>
                            </button>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                    <!--vehicle there end view start here -->
                  </div>
                </div>
              </ng-container>
            </form>
          </ng-container>
          <!-- Vehicle there end here-->
          <!--Truck details card start here-->
          <!--When status is confirmed-->
          <div
            class="card"
            *ngIf="inProcessStatusDisplay.includes(orderData.state?.code)"
          >
            <!-- truck detail view start here-->
            <div class="card-body none-sq-price truck-detail-view">
              <div
                class="card-header-custom d-flex justify-content-between align-items-start"
              >
                <div class="card-heading-details">
                  <h3>Provide Vehicle Details</h3>
                </div>
                <a
                  class="btn btn-primary truck-detail-add"
                  (click)="editTruckDetailsMode = true"
                  *ngIf="
                    editBtnDisplay.includes(orderData.state?.code) &&
                    orderData.truckDetail?.truckNo &&
                    !editTruckDetailsMode
                  "
                  >Edit Truck Details</a
                >
              </div>
              <div
                class="truck-detail-list"
                *ngIf="orderData.truckDetail?.truckNo && !editTruckDetailsMode"
              >
                <ul>
                  <li>
                    <strong>Truck No : </strong
                    >{{ orderData?.truckDetail?.truckNo }}
                  </li>
                  <li>
                    <strong>Driver Mobile number : </strong
                    >{{ orderTruckDetailForm.value.truckDetail.mobileNo }}
                  </li>
                  <li>
                    <strong>LR Number : </strong
                    >{{ orderTruckDetailForm.value.truckDetail.lrNumber }}
                  </li>
                  <li>
                    <strong>Date & Time : </strong
                    >{{ getDateTimeofStatus(orderStatus['truck-assigned']) }}
                  </li>
                  <li class="notes">
                    <strong>Notes : </strong
                    >{{ orderTruckDetailForm.value.truckDetail.notes }}
                  </li>
                </ul>
                <div
                  class="attachment"
                  *ngIf="orderTruckDetailForm.value.truckDetail.lrAttachment"
                >
                  <a
                    (click)="
                      fileDownload(
                        orderData?.truckDetail.lrAttachment
                          .path,
                        orderData?.truckDetail.lrAttachment.name
                      )
                    "
                    >{{
                      orderTruckDetailForm.value.truckDetail.lrAttachment.name
                    }}<svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.729 20.8334C5.31234 20.8334 4.94775 20.6772 4.63525 20.3647C4.32275 20.0522 4.1665 19.6876 4.1665 19.2709V15.547H5.729V19.2709H19.2707V15.547H20.8332V19.2709C20.8332 19.6876 20.6769 20.0522 20.3644 20.3647C20.0519 20.6772 19.6873 20.8334 19.2707 20.8334H5.729ZM12.4998 16.849L7.4738 11.823L8.59359 10.7032L11.7186 13.8282V4.16675H13.2811V13.8282L16.4061 10.7032L17.5259 11.823L12.4998 16.849Z"
                        fill="#025C54"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <ng-container
                *ngIf="
                  orderData.state?.code === 'inProcess' || editTruckDetailsMode
                "
              >
                <form
                  [formGroup]="orderTruckDetailForm"
                  (ngSubmit)="addTruckDetails()"
                >
                  <div class="order-form" formGroupName="truckDetail">
                    <div class="row">
                      <div class="mb-3 col-lg-6">
                        <label class="form-label">Truck No *</label>
                        <ng-select
                          class="truck-number"
                          aria-label="Default select example"
                          placeholder="Select truck"
                          formControlName="truckId"
                          (change)="changeTruck($event)"
                        >
                          <ng-container
                            *ngFor="let item of trucksList; let i = index"
                          >
                            <ng-option
                              *ngIf="
                                item?.availableLimit >= orderData?.totalWeight
                              "
                              value="{{ item?._id }}"
                            >
                              <h5>{{ item?.truckNumber }}</h5>
                              <ul class="truck-available-limit d-flex">
                                <li class="available-limit">
                                  Available limit :
                                  <span>{{ item?.availableLimit }}KG</span>
                                </li>
                                <li>
                                  Total limit :
                                  <span>{{ item?.totalLimit }}KG</span>
                                </li>
                              </ul>
                            </ng-option>
                          </ng-container>
                        </ng-select>
                        <bst-frontend-form-field-error-display
                          errorMsg="{{ displayErrMessage?.truckNo }}"
                        ></bst-frontend-form-field-error-display>
                      </div>
                      <div class="mb-3 col-lg-4">
                        <label for="driver-mobile-no" class="form-label"
                          >Driver Number *</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="driver-mobile-no"
                          aria-describedby="emailHelp"
                          formControlName="mobileNo"
                        />
                        <bst-frontend-form-field-error-display
                          errorMsg="{{ displayErrMessage?.mobileNo }}"
                        ></bst-frontend-form-field-error-display>
                      </div>
                      <div class="col-lg-12 mb-3" *ngIf="selectedTruck">
                        <div class="truck-limit-status">
                          <ul class="d-flex">
                            <li class="limit-status total-limit">
                              Total Limit : {{ selectedTruck.totalLimit }}KG
                            </li>
                            <li class="limit-status available-limit">
                              Available Limit :{{
                                selectedTruck.availableLimit
                              }}KG
                            </li>
                            <li class="limit-status used-limit">
                              Used Limit : {{ selectedTruck.usedLimit }}KG
                            </li>
                          </ul>
                        </div>
                      </div>
                      
                      <div class="mb-3 col-lg-4">
                        <label for="lr-number" class="form-label"
                          >LR Number *</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="lr-number"
                          aria-describedby="emailHelp"
                          formControlName="lrNumber"
                        />
                        <bst-frontend-form-field-error-display
                          errorMsg="{{ displayErrMessage?.lrNumber }}"
                        ></bst-frontend-form-field-error-display>
                      </div>
                      <div class="mb-3 col-lg-4">
                        <label for="lr-attachment" class="form-label"
                          >Attach LR (Pdf) *</label
                        >
                        <input
                          type="file"
                          class="form-control"
                          id="lr-attachment"
                          aria-describedby="emailHelp"
                          (change)="onFileChange($event, 'lrAttachment')"
                        />
                        <bst-frontend-form-field-error-display
                          errorMsg="{{ displayErrMessage?.file }}"
                        ></bst-frontend-form-field-error-display>
                      </div>
                      <div class="mb-3 col-lg-12">
                        <label for="truck-notes" class="form-label"
                          >Notes</label
                        >
                        <textarea
                          type="text"
                          class="form-control"
                          id="truck-notes"
                          placeholder="Enter notes"
                          aria-describedby="emailHelp"
                          formControlName="notes"
                        ></textarea>
                      </div>
                      <div class="col-lg-12">
                        <button
                          type="submit"
                          class="btn btn-primary truck-detail-add"
                          [disabled]="loader"
                        >
                          <ng-container *ngIf="!loader; else showLoader">
                            Send Truck Detail
                          </ng-container>
                          <ng-template #showLoader>
                            <span
                              class="spinner-border spinner-border-sm"
                              aria-hidden="true"
                            ></span>
                            <span role="status">Loading...</span>
                          </ng-template>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </ng-container>
            </div>
            <!-- truck detail edit start here-->
          </div>
          <!--Truck details card start ends-->
          <!--Estimation starts-->
          <!-- estimate provide add/edit card start here -->
          <!--Status is requested or edit estimate -->
          <div
            class="card"
            *ngIf="
              orderData.state?.code === orderStatus.requested ||
              editEstimateMode
            "
          >
            <div class="card-body estimate-edit">
              <div
                class="card-header-custom d-flex justify-content-between align-items-start"
              >
                <h3>Provide Estimate</h3>

                <div class="card-action-btn">
                  <button
                    (click)="submitEstimate()"
                    class="btn btn-primary"
                    [disabled]="loader"
                  >
                    <ng-container *ngIf="!loader; else showLoader">
                      Add Estimate
                    </ng-container>
                    <ng-template #showLoader>
                      <span
                        class="spinner-border spinner-border-sm"
                        aria-hidden="true"
                      ></span>
                      <span role="status">Loading...</span>
                    </ng-template>
                  </button>
                </div>
              </div>
              <form [formGroup]="orderEstimateForm">
                <div class="order-form row">
                  <div
                    class="form-group d-flex align-items-center col-lg-12 mb-3"
                  >
                    <h5>
                      <span>{{ orderData.totalWeight }}KG </span>
                      X
                    </h5>
                    <div class="estimate-input position-relative">
                      <h5>Rs.</h5>
                      <input
                        type="number"
                        id="order_amount"
                        class="form-control estimate-input"
                        placeholder="Enter 1 kg price"
                        formControlName="estimate"
                      />
                    </div>
                    <h6>
                      = ₹{{
                        orderEstimateForm.value.estimate * orderData.totalWeight
                      }}
                    </h6>
                  </div>
                  <ng-container formArrayName="otherCharges">
                    <div
                      class="form-group col-lg-3 mb-3"
                      *ngFor="
                        let charge of otherCharges.controls;
                        let i = index
                      "
                      [formGroupName]="i"
                    >
                      <label for="first-name" class="form-label">{{
                        charge.value.label
                      }}</label>
                      <input
                        type="number"
                        class="form-control"
                        formControlName="charge"
                      />
                    </div>
                  </ng-container>
                  <div
                    class="form-group col-lg-12 order-total-cost d-flex flex-column"
                  >
                    <p>Order total costing is</p>
                    <h4>₹{{ calculateTotalEstimate() }}</h4>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!-- estimate provide add/edit card end here -->
          <!-- estimate provide view card start here -->
          <div
            class="card"
            *ngIf="
              !hideEstimateCard.includes(orderData.state?.code) &&
              !editEstimateMode
            "
          >
            <div class="card-body estimate-edit">
              <div
                class="card-header-custom d-flex justify-content-between align-items-start"
              >
                <div class="left d-flex flex-column">
                  <h3>Provide Estimate</h3>
                  <div class="bst-date-time d-flex">
                    <p *ngIf="orderStatus.estimated">
                     <span>Estimate Provide</span> 
                      {{ getDateTimeofStatus(orderStatus.estimated) }}
                    </p>
                    <p *ngIf="getDateTimeofStatus(orderStatus.confirmed)">
                      <span>Customer Confirmed</span>
                      {{ getDateTimeofStatus(orderStatus.confirmed) }}
                    </p>
                  </div>
               </div>
                <div class="card-action-btn d-flex">
                  <a
                    *ngIf="editBtnDisplay.includes(orderData.state?.code)"
                    (click)="open(modalContent)"
                    class="btn btn-primary"
                    >Add more charges</a
                  >
                  <a
                    *ngIf="
                      editBtnDisplay.includes(orderData.state?.code) &&
                      !IsPartLoadOrderType
                    "
                    (click)="editEstimateMode = true"
                    class="btn btn-primary"
                    >Edit Estimate</a
                  >
                </div>
              </div>
              <div class="counter-offer-view">
                <ng-container
                  *ngIf="orderData.orderConfirmation?.action === 'reject'"
                >
                  <div class="alert alert-warning" role="alert">
                    <h6>
                      {{
                        orderData.orderConfirmation.reason
                          ? orderData.orderConfirmation.reason
                          : 'Price is too high!'
                      }}
                    </h6>
                    <p>{{ orderData.orderConfirmation.comment }}</p>
                  </div>
                </ng-container>
              </div>
              <div class="estimate-provide-list-view d-flex flex-column">
                <h6>Order summary</h6>
                <div class="estimate-provide-list-view-details">
                  <ul class="d-flex flex-column">
                    <li class="d-flex justify-content-between">
                      <p>Product</p>
                      <span
                        >₹{{
                          IsPartLoadOrderType
                            ? orderData.productAmount
                            : orderData.totalWeight * orderData.estimate
                        }}</span
                      >
                    </li>
                  </ul>
                </div>
                <div
                  class="estimate-provide-list-view-details extra-charges d-flex flex-column"
                >
                  <div
                    class="d-flex justify-content-between"
                    *ngFor="let charge of otherCharges.value; index as i"
                  >
                    <div class="extra-charge-details d-flex align-items-center">
                      <p>{{ charge.label }}</p>
                      <ng-container
                        *ngIf="
                          editBtnDisplay.includes(orderData.state?.code) &&
                          charge.isEdit
                        "
                      >
                        <a (click)="open(modalContent, true, i)">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_319_2883)">
                              <path
                                d="M2.5 14.375V17.5H5.625L14.8417 8.28333L11.7167 5.15833L2.5 14.375ZM17.2583 5.86667C17.5833 5.54167 17.5833 5.01667 17.2583 4.69167L15.3083 2.74167C14.9833 2.41667 14.4583 2.41667 14.1333 2.74167L12.6083 4.26667L15.7333 7.39167L17.2583 5.86667Z"
                                fill="#004B64"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_319_2883">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </a>
                        <a (click)="removeCharge(i)">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_319_2888)">
                              <path
                                d="M5.00033 15.8333C5.00033 16.75 5.75033 17.5 6.66699 17.5H13.3337C14.2503 17.5 15.0003 16.75 15.0003 15.8333V5.83333H5.00033V15.8333ZM15.8337 3.33333H12.917L12.0837 2.5H7.91699L7.08366 3.33333H4.16699V5H15.8337V3.33333Z"
                                fill="#E74C3C"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_319_2888">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </a>
                      </ng-container>
                    </div>
                    <span>₹{{ charge.charge }}</span>
                  </div>
                </div>
                <div class="estimate-provide-list-view-details">
                  <h4 class="d-flex justify-content-between">
                    Estimate total
                    <span>₹{{ calculateTotalEstimate() }}</span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <!-- estimate provide view card end here -->
          <!--Estimation ends-->

          <!-- new order details card start here-->
          <div class="card">
            <div class="card-body">
              <div
                class="card-header-custom d-flex justify-content-between align-items-start"
              >
                <div class="card-header-letf">
                  <h3>{{ getOrderType(orderData.type) }}</h3>
                  <div class="inside-label">
                    <p>Total Weight : {{ orderData.totalWeight }}KG</p>
                  </div>
                  <div class="inside-label">
                    <p>Order Date & Time : {{ orderData.orderDate }}</p>
                  </div>
                </div>
                <div class="card-action-btn d-flex">
                  <a
                    class="btn btn-primary"
                    (click)="addOrderItem()"
                    data-bs-toggle="modal"
                    data-bs-target="#Add-new-pickup"
                    >Add new</a
                  >

                  <a class="btn btn-primary" (click)="sendNotification()"
                    >Send Notificatoin</a
                  >
                </div>
              </div>
              <div class="order-item-list d-flex flex-column">
                <hr />
                <!--order item start here -->
                <div
                  class="order-item-card d-flex flex-column"
                  *ngFor="
                    let order of orderData.items;
                    index as oi;
                    last as isLast
                  "
                >
                  <ng-container *ngIf="IsPartLoadOrderType && oi === 0">
                    <div
                      *ngFor="
                        let deliver of order.deliveries;
                        index as di;
                        last as isLast
                      "
                      [ngClass]="{
                        'order-pickup-list': !isLast,
                        'order-deliver-list': isLast
                      }"
                    >
                      <div class="pickup-icon deliver">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6 20C5.16667 20 4.45833 19.7083 3.875 19.125C3.29167 18.5417 3 17.8333 3 17C2.45 17 1.97933 16.8043 1.588 16.413C1.196 16.021 1 15.55 1 15V6C1 5.45 1.196 4.97933 1.588 4.588C1.97933 4.196 2.45 4 3 4H15C15.55 4 16.021 4.196 16.413 4.588C16.8043 4.97933 17 5.45 17 6V8H19.5C19.6667 8 19.8167 8.03333 19.95 8.1C20.0833 8.16667 20.2 8.26667 20.3 8.4L22.8 11.725C22.8667 11.8083 22.9167 11.9 22.95 12C22.9833 12.1 23 12.2083 23 12.325V16C23 16.2833 22.904 16.5207 22.712 16.712C22.5207 16.904 22.2833 17 22 17H21C21 17.8333 20.7083 18.5417 20.125 19.125C19.5417 19.7083 18.8333 20 18 20C17.1667 20 16.4583 19.7083 15.875 19.125C15.2917 18.5417 15 17.8333 15 17H9C9 17.8333 8.70833 18.5417 8.125 19.125C7.54167 19.7083 6.83333 20 6 20ZM6 18C6.28333 18 6.521 17.904 6.713 17.712C6.90433 17.5207 7 17.2833 7 17C7 16.7167 6.90433 16.4793 6.713 16.288C6.521 16.096 6.28333 16 6 16C5.71667 16 5.479 16.096 5.287 16.288C5.09567 16.4793 5 16.7167 5 17C5 17.2833 5.09567 17.5207 5.287 17.712C5.479 17.904 5.71667 18 6 18ZM18 18C18.2833 18 18.5207 17.904 18.712 17.712C18.904 17.5207 19 17.2833 19 17C19 16.7167 18.904 16.4793 18.712 16.288C18.5207 16.096 18.2833 16 18 16C17.7167 16 17.4793 16.096 17.288 16.288C17.096 16.4793 17 16.7167 17 17C17 17.2833 17.096 17.5207 17.288 17.712C17.4793 17.904 17.7167 18 18 18ZM17 13H21.25L19 10H17V13Z"
                            fill="#004B64"
                          />
                        </svg>
                      </div>
                      <div class="order-address view-address">
                        <div class="location-heading d-flex">
                          <h5>Delivery Location</h5>
                          <div class="order-label-action d-flex">
                            <span class="order-label">{{
                              deliver.type | uppercase
                            }}</span>
                            <a
                              class="order-label"
                              (click)="
                                openAddress({
                                  type: 'delivery',
                                  order: order._id,
                                  address: deliver,
                                  deliveryIndex: di,
                                  index: oi
                                })
                              "
                              >CHANGE</a
                            >
                            <a
                              class="order-label remove"
                              href="#"
                              style="display: none"
                              >REMOVE</a
                            >
                          </div>
                        </div>
                        <ul>
                          <li class="address">
                            <strong>{{ deliver.companyName }}</strong
                            >OFFICE NO.
                            {{ deliver.buildingName }}
                            {{ deliver.locality }}
                            {{ deliver.city }}, India
                            {{ deliver.pincode }}
                          </li>
                          <li>
                            <strong>Contact person name</strong
                            >{{ deliver.contactName }}
                          </li>
                          <li>
                            <strong>Mobile Number</strong
                            >{{ deliver.contactNumber }}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <hr />
                  </ng-container>
                  <ng-container *ngIf="order.products.length > 0">
                    <div
                      class="order-item-product-details d-flex justify-content-between"
                    >
                      <div class="order-item-product-left d-flex flex-column">
                        <h3>
                          <span *ngIf="IsPartLoadOrderType"
                            >₹{{ order.itemAmount }}</span
                          >
                          {{ singleItemTotalWeight(order.products) }}KG
                        </h3>
                        <p *ngFor="let product of order.products">
                          {{ product?.product?.name }} |
                          {{
                            product?.subItem ? product?.subItem?.name + ' |' : ''
                          }}
                          {{ product?.weight }}KG | {{ product?.quantity }} Qty
                        </p>
                      </div>
                      <div class="order-item-action">
                        <bst-frontend-action-button
                          [actions]="dataTableActions"
                          [data]="order"
                          [id]="order._id"
                          [name]="order._id"
                          [title]="'Delete Item'"
                          [deleteEvent]="true"
                          (emitter)="onCaptureEvent($event)"
                          (deleteEmitter)="deleteOrderItem($event)"
                        ></bst-frontend-action-button>
                        <!-- <a (click)="openEditItem(editProduct, oi)">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_319_2883)">
                              <path
                                d="M2.5 14.375V17.5H5.625L14.8417 8.28333L11.7167 5.15833L2.5 14.375ZM17.2583 5.86667C17.5833 5.54167 17.5833 5.01667 17.2583 4.69167L15.3083 2.74167C14.9833 2.41667 14.4583 2.41667 14.1333 2.74167L12.6083 4.26667L15.7333 7.39167L17.2583 5.86667Z"
                                fill="#004B64"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_319_2883">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </a>
                        <a (click)="deleteOrderItem(order._id, oi)">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_319_2888)">
                              <path
                                d="M5.00033 15.8333C5.00033 16.75 5.75033 17.5 6.66699 17.5H13.3337C14.2503 17.5 15.0003 16.75 15.0003 15.8333V5.83333H5.00033V15.8333ZM15.8337 3.33333H12.917L12.0837 2.5H7.91699L7.08366 3.33333H4.16699V5H15.8337V3.33333Z"
                                fill="#E74C3C"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_319_2888">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </a> -->
                      </div>
                    </div>
                    <div class="order-pickuo-drop">
                      <div
                        class="order-pickup-list"
                        *ngFor="let pickup of order.pickUps"
                      >
                        <div class="pickup-icon">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 11.75C12.4833 11.75 12.8958 11.5792 13.2375 11.2375C13.5792 10.8958 13.75 10.4833 13.75 10C13.75 9.51667 13.5792 9.10417 13.2375 8.7625C12.8958 8.42083 12.4833 8.25 12 8.25C11.5167 8.25 11.1042 8.42083 10.7625 8.7625C10.4208 9.10417 10.25 9.51667 10.25 10C10.25 10.4833 10.4208 10.8958 10.7625 11.2375C11.1042 11.5792 11.5167 11.75 12 11.75ZM12.025 21.7C11.9083 21.7 11.8 21.6833 11.7 21.65C11.6 21.6167 11.5083 21.5667 11.425 21.5C8.94167 19.3167 7.08333 17.2917 5.85 15.425C4.61667 13.5583 4 11.8167 4 10.2C4 7.7 4.80417 5.70833 6.4125 4.225C8.02083 2.74167 9.88333 2 12 2C14.1167 2 15.9792 2.74167 17.5875 4.225C19.1958 5.70833 20 7.7 20 10.2C20 11.8167 19.3833 13.5583 18.15 15.425C16.9167 17.2917 15.0583 19.3167 12.575 21.5C12.4917 21.5667 12.4042 21.6167 12.3125 21.65C12.2208 21.6833 12.125 21.7 12.025 21.7Z"
                              fill="#42887D"
                            />
                          </svg>
                        </div>
                        <div class="order-address view-address">
                          <div class="location-heading d-flex">
                            <h5>Pickup Location</h5>
                            <div class="order-label-action d-flex">
                              <span class="order-label">{{
                                pickup.type | uppercase
                              }}</span>
                              <a
                                class="order-label"
                                (click)="
                                  openAddress({
                                    type: 'pickup',
                                    order: order._id,
                                    address: pickup,
                                    index: oi
                                  })
                                "
                                >CHANGE</a
                              >
                            </div>
                          </div>
                          <ul>
                            <li class="address">
                              <strong>{{ pickup.companyName }}</strong>
                              {{ pickup.buildingName }},
                              {{ pickup.locality }},
                              {{ pickup.city }}, India {{ pickup.pincode }}
                            </li>
                            <li>
                              <strong>Contact person name</strong
                              >{{ pickup.contactName }}
                            </li>
                            <li>
                              <strong>Mobile Number</strong
                              >{{ pickup.contactNumber }}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <ng-container *ngIf="!IsPartLoadOrderType">
                        <div
                          *ngFor="
                            let deliver of order.deliveries;
                            index as di;
                            last as isLast
                          "
                          [ngClass]="{
                            'order-pickup-list': !isLast,
                            'order-deliver-list': isLast
                          }"
                        >
                          <div class="pickup-icon deliver">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6 20C5.16667 20 4.45833 19.7083 3.875 19.125C3.29167 18.5417 3 17.8333 3 17C2.45 17 1.97933 16.8043 1.588 16.413C1.196 16.021 1 15.55 1 15V6C1 5.45 1.196 4.97933 1.588 4.588C1.97933 4.196 2.45 4 3 4H15C15.55 4 16.021 4.196 16.413 4.588C16.8043 4.97933 17 5.45 17 6V8H19.5C19.6667 8 19.8167 8.03333 19.95 8.1C20.0833 8.16667 20.2 8.26667 20.3 8.4L22.8 11.725C22.8667 11.8083 22.9167 11.9 22.95 12C22.9833 12.1 23 12.2083 23 12.325V16C23 16.2833 22.904 16.5207 22.712 16.712C22.5207 16.904 22.2833 17 22 17H21C21 17.8333 20.7083 18.5417 20.125 19.125C19.5417 19.7083 18.8333 20 18 20C17.1667 20 16.4583 19.7083 15.875 19.125C15.2917 18.5417 15 17.8333 15 17H9C9 17.8333 8.70833 18.5417 8.125 19.125C7.54167 19.7083 6.83333 20 6 20ZM6 18C6.28333 18 6.521 17.904 6.713 17.712C6.90433 17.5207 7 17.2833 7 17C7 16.7167 6.90433 16.4793 6.713 16.288C6.521 16.096 6.28333 16 6 16C5.71667 16 5.479 16.096 5.287 16.288C5.09567 16.4793 5 16.7167 5 17C5 17.2833 5.09567 17.5207 5.287 17.712C5.479 17.904 5.71667 18 6 18ZM18 18C18.2833 18 18.5207 17.904 18.712 17.712C18.904 17.5207 19 17.2833 19 17C19 16.7167 18.904 16.4793 18.712 16.288C18.5207 16.096 18.2833 16 18 16C17.7167 16 17.4793 16.096 17.288 16.288C17.096 16.4793 17 16.7167 17 17C17 17.2833 17.096 17.5207 17.288 17.712C17.4793 17.904 17.7167 18 18 18ZM17 13H21.25L19 10H17V13Z"
                                fill="#004B64"
                              />
                            </svg>
                          </div>
                          <div class="order-address view-address">
                            <div class="location-heading d-flex">
                              <h5>Delivery Location {{ di + 1 }}</h5>
                              <div class="order-label-action d-flex">
                                <span class="order-label">{{
                                  deliver.type | uppercase
                                }}</span>
                                <a
                                  class="order-label"
                                  (click)="
                                    openAddress({
                                      type: 'delivery',
                                      order: order._id,
                                      address: deliver,
                                      deliveryIndex: di,
                                      index: oi
                                    })
                                  "
                                  >CHANGE</a
                                >
                                <a
                                  *ngIf="order.deliveries.length > 1"
                                  class="order-label remove"
                                  (click)="removeAddress(oi, di, order._id)"
                                  >REMOVE</a
                                >
                              </div>
                            </div>
                            <ul>
                              <li class="address">
                                <strong>{{ deliver.companyName }}</strong
                                >OFFICE NO.
                                {{ deliver.buildingName }},
                                {{ deliver.locality }},
                                {{ deliver.city }}, India
                                {{ deliver.pincode }}
                              </li>
                              <li>
                                <strong>Contact person name</strong
                                >{{ deliver.contactName }}
                              </li>
                              <li>
                                <strong>Mobile Number</strong
                                >{{ deliver.contactNumber }}
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="add-new-address">
                          <a
                            class="add-new-btns d-flex align-items-center"
                            (click)="
                              openAddress({
                                index: oi,
                                type: 'add',
                                order: order._id
                              })
                            "
                            ><svg
                              width="30"
                              height="30"
                              viewBox="0 0 30 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="15"
                                cy="15"
                                r="14.5"
                                fill="#42887D"
                                stroke="#42887D"
                              />
                              <path
                                d="M15 21.0416C14.7639 21.0416 14.566 20.9618 14.4063 20.8021C14.2466 20.6423 14.1667 20.4444 14.1667 20.2083V15.8333H9.79171C9.5556 15.8333 9.35768 15.7535 9.19796 15.5937C9.03824 15.434 8.95837 15.2361 8.95837 15C8.95837 14.7639 9.03824 14.566 9.19796 14.4062C9.35768 14.2465 9.5556 14.1666 9.79171 14.1666H14.1667V9.79165C14.1667 9.55554 14.2466 9.35762 14.4063 9.1979C14.566 9.03817 14.7639 8.95831 15 8.95831C15.2362 8.95831 15.4341 9.03817 15.5938 9.1979C15.7535 9.35762 15.8334 9.55554 15.8334 9.79165V14.1666H20.2084C20.4445 14.1666 20.6424 14.2465 20.8021 14.4062C20.9618 14.566 21.0417 14.7639 21.0417 15C21.0417 15.2361 20.9618 15.434 20.8021 15.5937C20.6424 15.7535 20.4445 15.8333 20.2084 15.8333H15.8334V20.2083C15.8334 20.4444 15.7535 20.6423 15.5938 20.8021C15.4341 20.9618 15.2362 21.0416 15 21.0416Z"
                                fill="white"
                              />
                            </svg>
                            Add new Delivery</a
                          >
                        </div>
                      </ng-container>
                    </div>
                  </ng-container>
                  <hr *ngIf="!isLast" />
                </div>
                <!--order item end here -->

                <p *ngIf="orderData.note">
                  <span>Note: </span> {{ orderData.note }}
                </p>
              </div>
            </div>
          </div>
          <!-- new order details card end here-->
          <!--customer detail start here-->
          <div class="card">
            <div class="card-body">
              <div
                class="card-header-custom d-flex justify-content-between align-items-start"
              >
                <div class="card-heading-details">
                  <h3>Customer Details</h3>
                </div>
              </div>
              <div class="customer-details">
                <ul>
                  <li>
                    <strong>Name Of Customer</strong>
                    {{ orderData?.orderBy?.name | titlecase }}
                  </li>
                  <li>
                    <strong>Mobile Number</strong
                    >{{ orderData?.orderBy?.mobileNo }}
                  </li>
                  <li><strong>Email</strong>{{ orderData?.orderBy?.email }}</li>
                </ul>
                <ul>
                  <li *ngIf="orderData?.orderBy?.gstNo">
                    <strong>GST Number</strong>{{ orderData?.orderBy.gstNo }}
                  </li>
                  <li *ngIf="orderData?.orderBy?.companyName">
                    <strong>Company Name</strong
                    >{{ orderData?.orderBy.companyName }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!--customer detail start here-->
          <!-- modal start here-->
          <!-- Button trigger modal -->

          <!-- add new pickup modal start here  -->
          <div
            class="modal fade bst-modal"
            id="Add-new-pickup"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="Add-new-pickupLabel"
            aria-hidden="true"
          >
            <div
              class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered"
            >
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="Add-new-pickupLabel">
                    Add new pickup & delivery location
                  </h1>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div class="order-item-steps">
                    <ul
                      class="d-flex"
                      *ngIf="
                        !IsPartLoadOrderType ||
                        (IsPartLoadOrderType &&
                          !this.allExistingSelectedLocaiton.length)
                      "
                    >
                      <li
                        class="d-flex flex-column align-items-center"
                        [ngClass]="activeTab === 1 ? 'active' : ''"
                      >
                        <span>1</span> Pickup location
                      </li>
                      <li
                        class="d-flex flex-column align-items-center"
                        [ngClass]="activeTab === 2 ? 'active' : ''"
                      >
                        <span>2</span> Delivery location
                      </li>
                      <li
                        class="d-flex flex-column align-items-center"
                        [ngClass]="activeTab === 3 ? 'active' : ''"
                      >
                        <span>3</span> Product Details
                      </li>
                    </ul>
                    <ul
                      class="d-flex"
                      *ngIf="
                        IsPartLoadOrderType &&
                        this.allExistingSelectedLocaiton.length
                      "
                    >
                      <li
                        class="d-flex flex-column align-items-center"
                        [ngClass]="activeTab === 1 ? 'active' : ''"
                      >
                        <span>1</span> Pickup location
                      </li>
                      <li
                        class="d-flex flex-column align-items-center"
                        [ngClass]="activeTab === 3 ? 'active' : ''"
                      >
                        <span>2</span> Product Details
                      </li>
                    </ul>
                  </div>
                  <!-- address list start here-->
                  <div
                    class="order-address-list d-flex flex-column"
                    *ngIf="activeTab === 1"
                  >
                  <ng-select
                  *ngIf="orderData.type === orderType.partLoadOffice"
                    class=""
                    aria-label="Default select example"
                    placeholder="Select Address"
                    [(ngModel)]="selectedAddressType"
                    (change)="onAddressTypeChange($event)"
                  >
                    <ng-option value="office-address"
                      >BST office address</ng-option
                    >
                    <ng-option value="user-address">User Address</ng-option>
                  </ng-select>
                    <ng-container *ngFor="let address of deliveryAddresses">
                      <!-- <ng-container
                        *ngIf="!isAddressInPickups(address.addressId)"
                      > -->
                        <ng-container
                          *ngIf="!IsPartLoadOrderType; else partLoadTabs"
                        >
                          <div
                            class="order-address-item order-address"
                            [ngClass]="{
                              activeaddress:
                                selectedLocation?.pickup?.addressId ===
                                address.addressId
                            }"
                          >
                            <ul (click)="selectAddress(address, 'pickup')">
                              <li class="address">
                                <strong>{{ address.companyName }} </strong>
                                {{ address.buildingName }},
                                {{ address.locality }}, {{ address.city }},
                                India
                                {{ address.pincode }}
                              </li>
                              <li>
                                <strong>Contact person name</strong
                                >{{ address.contactName }}
                              </li>
                              <li>
                                <strong>Mobile Number</strong
                                >{{ address.contactNumber }}
                              </li>
                            </ul>
                          </div>
                        </ng-container>
                        <ng-template #partLoadTabs>
                          <div
                            class="order-address-item order-address"
                            [ngClass]="{
                              activeaddress:
                                selectedLocation?.pickup?.addressId ===
                                address.addressId
                            }"
                          >
                            <ul (click)="selectAddress(address, 'pickup')">
                              <li class="address">
                                <strong>{{ address.companyName }} </strong>
                                {{ address.buildingName }},
                                {{ address.locality }}, {{ address.city }},
                                India
                                {{ address.pincode }}
                              </li>
                              <li>
                                <strong>Contact person name</strong
                                >{{ address.contactName }}
                              </li>
                              <li>
                                <strong>Mobile Number</strong
                                >{{ address.contactNumber }}
                              </li>
                            </ul>
                          </div>
                        </ng-template>
                      <!-- </ng-container> -->
                    </ng-container>
                  </div>
                  <!-- address list end here-->
                  <!-- address list start here-->
                  <div
                    class="order-address-list d-flex flex-column"
                    *ngIf="activeTab === 2"
                  >
                  <ng-container
                  *ngIf="orderData.type === 'part-load-office'; else allAddress"
                > 
                <ng-container *ngFor="let address of addresses">
                  <div
                    class="order-address-item order-address"
                    [ngClass]="{
                      activeaddress:
                        selectedLocation?.deliver?.addressId ===
                        address.addressId
                    }"
                  >
                    <ul (click)="selectAddress(address, 'deliver')">
                      <li class="address">
                        <strong>{{ address.companyName }} </strong>
                        {{ address.buildingName }}, {{ address.locality }},
                        {{ address.city }}, India
                        {{ address.pincode }}
                      </li>
                      <li>
                        <strong>Contact person name</strong
                        >{{ address.contactName }}
                      </li>
                      <li>
                        <strong>Mobile Number</strong
                        >{{ address.contactNumber }}
                      </li>
                    </ul>
                  </div>
                </ng-container>
                </ng-container>
                <ng-template #allAddress>
                  <ng-container *ngFor="let address of deliveryAddresses">
                    <div
                      class="order-address-item order-address"
                      [ngClass]="{
                        activeaddress:
                          selectedLocation?.deliver?.addressId ===
                          address.addressId
                      }"
                    >
                      <ul (click)="selectAddress(address, 'deliver')">
                        <li class="address">
                          <strong>{{ address.companyName }} </strong>
                          {{ address.buildingName }}, {{ address.locality }},
                          {{ address.city }}, India
                          {{ address.pincode }}
                        </li>
                        <li>
                          <strong>Contact person name</strong
                          >{{ address.contactName }}
                        </li>
                        <li>
                          <strong>Mobile Number</strong
                          >{{ address.contactNumber }}
                        </li>
                      </ul>
                    </div>
                  </ng-container>
                </ng-template>
                  </div>
                  <!-- address list end here-->
                  <!--Product form start here -->
                  <form
                    [formGroup]="getItemFormGroup(orderData.items.length)"
                    class="product-order-details d-flex flex-column"
                    *ngIf="activeTab === 3"
                  >
                    <!-- <div formArrayName="items"> -->
                    <!-- <div
                        *ngFor="
                          let item of orderItems.at(orderData.items.length)
                            .value;
                          let itemIndex = index
                        "
                        [formGroupName]="itemIndex"
                      > -->
                    <div formArrayName="products">
                      <div
                        class="products-item"
                        *ngFor="
                          let product of getItemsProducts(
                            orderData.items.length
                          ).controls;
                          let i = index
                        "
                        [formGroupName]="i"
                      >
                        <h5 class="mb-3">
                          Product {{ i + 1 }}
                          <span
                            *ngIf="
                              getItemsProducts(orderData.items.length).length >
                              1
                            "
                            ><a
                              class="order-label remove"
                              (click)="removeProduct(orderData.items.length, i)"
                              >REMOVE</a
                            ></span
                          >
                        </h5>
                        <div class="row">
                          <div class="form-group mb-3 col-lg-12">
                            <label class="form-label">Select product</label>
                            <ng-select
                              [searchable]="true"
                              bindLabel="name"
                              formControlName="product"
                              (ngModelChange)="
                                onProductChange(
                                  $event,
                                  orderData.items.length,
                                  i
                                )
                              "
                            >
                              <ng-option
                                *ngFor="let option of productData"
                                [value]="option"
                              >
                                {{ option.name }}
                              </ng-option>
                            </ng-select>
                          </div>
                          <div
                            class="form-group mb-3 col-lg-12"
                            *ngIf="
                              getItemsProducts(orderData.items.length)
                                .at(i)
                                .get('product')?.value &&
                              getItemsProducts(orderData.items.length)
                                .at(i)
                                .get('product')?.value?.subItems.length > 0
                            "
                          >
                            <label class="form-label">Select sub product</label>
                            <ng-select
                              class=""
                              placeholder="Select sub-product"
                              bindLabel="name"
                              formControlName="subItem"
                            >
                              <ng-option
                                *ngFor="
                                  let subItem of getItemsProducts(
                                    orderData.items.length
                                  )
                                    .at(i)
                                    .get('product')?.value.subItems || []
                                "
                                [value]="subItem"
                              >
                                {{ subItem.name | titlecase }}
                              </ng-option>
                            </ng-select>
                          </div>
                          <div class="form-group col-lg-6">
                            <label class="form-label">Total weight</label>
                            <input
                              type="number"
                              class="form-control"
                              formControlName="weight"
                            />
                          </div>
                          <div class="form-group col-lg-6">
                            <label class="form-label">Qty</label>
                            <input
                              type="number"
                              class="form-control"
                              formControlName="quantity"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <a
                      class="add-new-btns d-flex align-items-center"
                      (click)="addProduct(orderData.items.length, -1)"
                      ><svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="15"
                          cy="15"
                          r="14.5"
                          fill="#42887D"
                          stroke="#42887D"
                        />
                        <path
                          d="M15 21.0416C14.7639 21.0416 14.566 20.9618 14.4063 20.8021C14.2466 20.6423 14.1667 20.4444 14.1667 20.2083V15.8333H9.79171C9.5556 15.8333 9.35768 15.7535 9.19796 15.5937C9.03824 15.434 8.95837 15.2361 8.95837 15C8.95837 14.7639 9.03824 14.566 9.19796 14.4062C9.35768 14.2465 9.5556 14.1666 9.79171 14.1666H14.1667V9.79165C14.1667 9.55554 14.2466 9.35762 14.4063 9.1979C14.566 9.03817 14.7639 8.95831 15 8.95831C15.2362 8.95831 15.4341 9.03817 15.5938 9.1979C15.7535 9.35762 15.8334 9.55554 15.8334 9.79165V14.1666H20.2084C20.4445 14.1666 20.6424 14.2465 20.8021 14.4062C20.9618 14.566 21.0417 14.7639 21.0417 15C21.0417 15.2361 20.9618 15.434 20.8021 15.5937C20.6424 15.7535 20.4445 15.8333 20.2084 15.8333H15.8334V20.2083C15.8334 20.4444 15.7535 20.6423 15.5938 20.8021C15.4341 20.9618 15.2362 21.0416 15 21.0416Z"
                          fill="white"
                        />
                      </svg>
                      Add More product
                    </a>
                    <!-- </div> -->
                    <!-- </div> -->
                  </form>

                  <!--Product form end here -->
                </div>
                <div class="modal-footer justify-content-between">
                  <div class="moda-left-btn d-flex">
                    <button
                      type="button"
                      class="btn btn-primary"
                      *ngIf="activeTab !== 1"
                      (click)="selectTab(orderData.items.length, activeTab - 1, 'previous')"
                    >
                      Previous
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary"
                      *ngIf="activeTab !== 3"
                      (click)="
                        selectTab(orderData.items.length, activeTab + 1, 'next', true)
                      "
                    >
                      Next
                    </button>
                  </div>
                  <button
                    *ngIf="activeTab === 3"
                    (click)="createItem(orderData.items.length)"
                    type="button"
                    class="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- add new pickup modal end here  -->
        </div>
      </div>
    </div>
  </div>
</body>

<ng-template #editProduct>
  <form
    [formGroup]="getItemFormGroup(activeItemIndex)"
    class="product-order-details d-flex flex-column"
  >
    <div formArrayName="products">
      <div
        *ngFor="
          let product of getItemsProducts(activeItemIndex).controls;
          let j = index
        "
        [formGroupName]="j"
      >
        <h5 class="mb-3">
          Product {{ j + 1 }}
          <span *ngIf="getItemsProducts(activeItemIndex).length > 1"
            ><a
              class="order-label remove"
              (click)="removeProduct(activeItemIndex, j)"
              >REMOVE</a
            ></span
          >
        </h5>
        <div class="row">
          <div class="form-group mb-3 col-lg-12">
            <label class="form-label">Select product</label>
            <ng-select
              placeholder="Select product"
              formControlName="product"
              [items]="productData"
              bindLabel="name"
              [compareWith]="compareFn"
              (ngModelChange)="onProductChange($event, activeItemIndex, j)"
            >
            </ng-select>
          </div>
          <!-- <div
            class="form-group mb-3 col-lg-12"
            *ngIf="
              getItemsProducts(activeItemIndex).at(j).get('product')?.value &&
              getItemsProducts(activeItemIndex).at(j).get('product')?.value
                ?.subItems?.length > 0
            "
          > -->
          <!-- <div
          class="form-group mb-3 col-lg-12"
          *ngIf="
            getItemsProducts(activeItemIndex).at(j).get('product')?.value &&
            getItemsProducts(activeItemIndex).at(j).get('subItem')?.value"
        > -->
          <div
          class="form-group mb-3 col-lg-12"
          *ngIf="
            getItemsProducts(activeItemIndex).at(j).get('product')?.value &&
            getSubItem(getItemsProducts(activeItemIndex).at(j).get('product')?.value).length > 0"
        >
            <label class="form-label">Select sub product</label>
            <ng-select
              placeholder="Select sub-product"
              formControlName="subItem"
              [items]="getSubItem(getItemsProducts(activeItemIndex).at(j).get('product')?.value)"
              bindLabel="name"
              [compareWith]="compareFn"
            >
          </ng-select>
            <!-- <ng-select
              placeholder="Select sub-product"
              formControlName="subItem"
              [items]="
                getItemsProducts(activeItemIndex).at(j).get('product')?.value
                  .subItems
              "
              bindLabel="name"
              [compareWith]="compareFn"
            >
            </ng-select> -->
          </div>
          <div class="form-group col-lg-6">
            <label class="form-label">Total weight</label>
            <input
              type="number"
              id="first-name"
              aria-describedby="first-name"
              class="form-control"
              formControlName="weight"
            />
          </div>
          <div class="form-group col-lg-6">
            <label class="form-label">Qty</label>
            <input
              type="number"
              id="first-name"
              aria-describedby="first-name"
              class="form-control"
              formControlName="quantity"
            />
          </div>
        </div>
      </div>
    </div>
    <a
      class="add-new-btns d-flex align-items-center"
      (click)="
        addProduct(activeItemIndex, getItemsProducts(activeItemIndex).length)
      "
      ><svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="15" cy="15" r="14.5" fill="#42887D" stroke="#42887D" />
        <path
          d="M15 21.0416C14.7639 21.0416 14.566 20.9618 14.4063 20.8021C14.2466 20.6423 14.1667 20.4444 14.1667 20.2083V15.8333H9.79171C9.5556 15.8333 9.35768 15.7535 9.19796 15.5937C9.03824 15.434 8.95837 15.2361 8.95837 15C8.95837 14.7639 9.03824 14.566 9.19796 14.4062C9.35768 14.2465 9.5556 14.1666 9.79171 14.1666H14.1667V9.79165C14.1667 9.55554 14.2466 9.35762 14.4063 9.1979C14.566 9.03817 14.7639 8.95831 15 8.95831C15.2362 8.95831 15.4341 9.03817 15.5938 9.1979C15.7535 9.35762 15.8334 9.55554 15.8334 9.79165V14.1666H20.2084C20.4445 14.1666 20.6424 14.2465 20.8021 14.4062C20.9618 14.566 21.0417 14.7639 21.0417 15C21.0417 15.2361 20.9618 15.434 20.8021 15.5937C20.6424 15.7535 20.4445 15.8333 20.2084 15.8333H15.8334V20.2083C15.8334 20.4444 15.7535 20.6423 15.5938 20.8021C15.4341 20.9618 15.2362 21.0416 15 21.0416Z"
          fill="white"
        />
      </svg>
      Add More product</a
    >
  </form>
</ng-template>

<ng-template #modalContent>
  <form
    class="product-order-details order-details d-flex flex-column address-form"
    [formGroup]="chargeForm"
  >
    <div class="row">
      <div class="form-group col-lg-12 mb-3">
        <label class="form-label">Charge name</label>
        <input
          type="text"
          class="form-control"
          formControlName="label"
          placeholder="Enter charge name "
        />
      </div>
      <div class="form-group col-lg-12 mb-3">
        <label class="form-label">Amount</label>
        <input
          type="number"
          class="form-control"
          formControlName="charge"
          placeholder="Enter amount"
        />
      </div>
    </div>
  </form>
</ng-template>
<ng-template #orderCancelModalContent>
  <div class="row">
    <div class="form-group col-lg-12 mb-3">
      <!-- <label class="form-label">Order Id : {{ orderData._id }}</label> -->
      <label class="form-label">Are you sure want to cancel Order?</label>
    </div>
  </div>
</ng-template>
