import {
  Component,
  OnInit,
  ViewChildren,
  ElementRef,
  AfterViewInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  FormGroup,
  FormArray,
  FormBuilder,
  Validators,
  FormControlName,
} from '@angular/forms';
import * as moment from 'moment';
import { NgbModalRef, NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import {
  OrderService,
  CommonService,
  GenericValidator,
} from '@bst-frontend/shared';
import { RealtimeOrderUpdateService } from '@bst-frontend/shared';
import { CommonModalComponent } from '@bst-frontend/common-ui';
import { forkJoin } from 'rxjs';

interface Charge {
  label: string;
  charge: number;
  isEdit: boolean
      isDiscount: boolean
}

declare const bootstrap: any;
@Component({
  selector: 'bst-frontend-view-order',
  templateUrl: './view-order.component.html',
  styleUrls: ['./view-order.component.css'],
})
export class ViewOrderComponent implements OnInit, AfterViewInit {
  id = '';
  loading = true
  loader = false;
  orderData: any = [];
  productData: any = [];
  appDetails: any
  addresses: any;
  deliveryAddresses: any;
  userAddresses: any
  companyAddresses: any
  orderType = {
    partLoadOffice: 'part-load-office',
    partLoadDoor: 'part-load-door',
    fullLoad: 'full_load'
  }
  selectedAddressType = 'office-address'
  truckCapacityData: any = [];
  orderForm!: FormGroup;
  orderEstimateForm!: FormGroup;
  chargeForm!: FormGroup;
  orderTruckDetailForm!: FormGroup;
  assignVehicleForm!: FormGroup;
  orderInvoiceForm!: FormGroup;
  dispatchOrderForm!: FormGroup;
  orderCancelForm!: FormGroup;
  orderDeliveredForm!: FormGroup;
  orderPaymentForm!: FormGroup;
  estimatedStatusDisplay = [
    'requested',
    'estimated',
    'inProcess',
    'truck-assigned',
    'outForPickUp',
    'dispatched',
    'delivered',
    'paid',
  ];
  inProcessStatusDisplay = [
    'inProcess',
    'truck-assigned',
    'outForPickUp',
    'dispatched',
    'delivered',
    'paid',
    'invoiceUploaded'
  ];
  truckAssignedStatusDisplay = [
    'truck-assigned',
    'outForPickUp',
    'dispatched',
    'delivered',
    'paid',
    'invoiceUploaded'
  ];
  vehicleThereStatusDisplay = [
    'outForPickUp',
    'dispatched',
    'delivered',
    'paid',
    'invoiceUploaded'
  ];
  loadedStatusDisplay = [
    'outForPickUp',
    'invoiceUploaded',
    'dispatched',
    'paid'
  ];
  dispatchedStatusDisplay = ['dispatched', 'delivered', 'paid'];
  deliveredStatusDisplay = ['delivered', 'paid', 'invoiceUploaded'];
  paidStatusDisplay = ['paid'];
  hideEstimateCard = [
    'requested',
    'admin-pending'
  ]
  editBtnDisplay = [
    'estimated',
    'confirmed',
    'requested',
    'rejected',
    'inProcess',
    'truck-assigned',
    'outForPickUp',
    'dispatched',
    'invoiceUploaded'
  ];

  truckDetailBlockDisplay = [
    'inProcess',
    'truck-assigned',
    'confirmation',
    'order-paid',
    'order-cancelled',
    'confirmed',
    'order-delivered',
  ];
  invoiceBlockDisplay = ['dispatched', 'order-paid', 'order-cancelled',  'invoiceUploaded', 'paid'];
  orderDispatchBlockDisplay = ['delivered', 'dispatched', 'invoiceUploaded', 'paid'];
  paymentBlockDisplay = [
    'invoice-upload',
    'order-delivered',
    'truck-assigned',
    'paid',
  ];
  cancelBtnDisplay = [
    'estimated',
    'confirmed',
    'requested',
    'inProcess',
    'truck-assigned',
  ];
  updateBtnDisplay = ['estimated', 'confirmed', 'requested'];
  dispatchBtnDisplay = ['outForPickUp', 'dispatched', 'delivered', 'paid'];
  deliverBtnDisplay = ['truck-assigned', 'paid'];
  editEstimateBtnDisplay = [
    'estimated',
    'confirmed',
    'requested',
    'inProcess',
    'outForPickUp',
    'truck-assigned',
    'dispatched',
  ];
  editTruckBtnDisplay = ['estimated', 'confirmed', 'truck-assigned'];
  editInvoiceBtnDisplay = ['dispatched', 'order-delivered'];
  paymentBlock = ['invoiceUploaded'];
  orderStatus = {
    requested: 'requested',
    estimated: 'estimated',
    rejected: 'rejected',
    inProcess: 'inProcess',
    confirmed: 'confirmed',
    'truck-assigned': 'truck-assigned',
    outForPickUp: 'outForPickUp',
    dispatched: 'dispatched',
    delivered: 'delivered',
    paid: 'paid',
    cancelled: 'cancelled',
    adminPending: 'admin-pending',
    invoiceUploaded: 'invoiceUploaded',
    loaded: 'loaded'
  };

  displayErrMessage: any = {};

  supportedMediaType = [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'application/pdf',
  ];
  maxUplodaSize = 10; // 10 MB;
  modalRef!: NgbModalRef;

  //Full Load
  editEstimateMode = false;
  editTruckDetailsMode = false;
  editDispatchOrderMode = false;
  charge: Charge[] = [
    { label: 'Tempo Charge', charge: 0, isDiscount: false, isEdit: true },
    { label: 'Labor Charge', charge: 0, isDiscount: false, isEdit: true },
    { label: 'LR Charge', charge: 0, isDiscount: false, isEdit: true },
  ];

  //Assign vehicle
  showVehicleNumberInput = false;
  vehicleAssignIndex = -1;

  //Create Order
  activeTab = 1
  selectedAddress: any
  showSubitem = false
  items: any
  selectedAddressData: any
  updatedAdressdata: any
  activeItemIndex = 0
  public allExistingSelectedLocaiton: any = [];
  trucksList:any = [];
  selectedTruck: any;

  dataTableActions = {
    // view: {
    //   cmd: 'view',
    // },
    edit: {
      cmd: 'edit',
    },
    delete: {
      cmd: 'delete',
      // page: this.page,
    },
  };
  
  @ViewChild('editProduct') modal!: ElementRef;
  @ViewChildren(FormControlName, { read: ElementRef })
  formInputElements: ElementRef[];

  constructor(
    private activeRoute: ActivatedRoute,
    private fb: FormBuilder,
    private genericValidator: GenericValidator,
    private modalService: NgbModal,
    private common: CommonService,
    private orderService: OrderService
  ) {
    this.formInputElements = [];
  }

  ngOnInit(): void {
    this.callForkJoin()
    this.id = this.activeRoute.snapshot.paramMap.get('id') || '';
    this.initForm();
    if (this.id) {
      this.getOrderDetails(this.id);
    }
  }

  callForkJoin(){
    const products =  this.orderService.getProducts()
    const truckCapacities =  this.orderService.getTruckCapacities()
    const appdetails =  this.orderService.getAppDetails()

    forkJoin([products, truckCapacities, appdetails]).subscribe((results: any) => {
      this.productData= results[0].data
      this.truckCapacityData = results[1].data
      this.appDetails = results[2].data.settings.partLoad
      // this.productData.forEach((e: any) => {
      //   e.productId = e._id
      //   e.subItems.forEach((es: any) => {
      //     es.productId = es._id
      //     delete es._id;
      //   });
      //   delete e._id;
      // });
      // console.log("this.productData", this.productData)
    });


  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.genericValidator.watchFormBlurEvent(
        this.orderTruckDetailForm,
        this.formInputElements
      );
      this.genericValidator.watchFormBlurEvent(
        this.orderInvoiceForm,
        this.formInputElements
      );
      this.genericValidator.watchFormBlurEvent(
        this.dispatchOrderForm,
        this.formInputElements
      );
      this.genericValidator.watchFormBlurEvent(
        this.orderEstimateForm,
        this.formInputElements
      );

      this.genericValidator.formErrors$.subscribe(
        (messages: { [key: string]: string }) => {
          this.displayErrMessage = messages;
        }
      );
    }, 10);
  }

  private initForm() {
    this.orderForm = this.fb.group({
      items: this.fb.array([])
    });
    this.orderEstimateForm = this.fb.group({
      estimate: [1, Validators.required],
      otherCharges: this.fb.array([]),
      isEdit: [false],
      amount: [''],
    });
    this.chargeForm = this.fb.group({
      label: ['', Validators.required],
      charge: ['', Validators.required],
      isEdit: [true],
      isDiscount: [false]
    });

    this.orderTruckDetailForm = this.fb.group({
      truckDetail: this.fb.group({
        truckId: ['', Validators.required],
        mobileNo: [
          '',
          [
            Validators.required,
            Validators.pattern('^([0-9]+)$'),
            Validators.minLength(10),
            Validators.maxLength(10),
          ],
        ],
        lrNumber: ['', Validators.required],
        notes: [''],
        lrAttachment: this.fb.group({
          path: [''],
          size: ['', Validators.required],
          name: [''],
          file: [''],
        }),
      }),
      isEdit: [false],
    });

    this.orderInvoiceForm = this.fb.group({
      invoiceDetail: this.fb.group({
        path: [''],
        size: ['', Validators.required],
        name: [''],
        file: [''],
      }),
      isEdit: [false],
    });
    this.dispatchOrderForm = this.fb.group({
      dispatchDetail: this.fb.group({
        attachment: this.fb.group({
          path: [''],
          size: ['', Validators.required],
          name: [''],
          file: [''],
        }),
        notes: [''],
      }),
      isEdit: [false],
    });
    this.assignVehicleForm = this.fb.group({
      pickUpVehicle: this.fb.array([]),
    });
    this.orderCancelForm = this.fb.group({});
    this.orderDeliveredForm = this.fb.group({});
    this.orderPaymentForm = this.fb.group({
      paymentDetail: this.fb.group({
        paymentType: [''],
        transactionId: [''],
      }),
      orderPaid: [false],
    });
  }

  getOrderType(type: string) {
    const typeData: any = {
      'full-load': 'Full load & clubbing',
      'part_load': 'Part Load',
      'part-load-door': 'Part Load - Door Step',
      'part-load-office': 'Part Load - Office',
    };
    return typeData[type];
  }

  getOrderStatus(status: string) {
    const statusData: any = {
      requested: 'Requested',
      estimated: 'Confirmation',
      rejected: 'Counter offer',
      inProcess: 'In Process',
      'truck-assigned': 'Truck Assigned',
      outForPickUp: 'Vehicle there',
      dispatched: 'Dispatched',
      delivered: 'Delivered',
      paid: 'Paid',
      cancelled: 'Order Cancelled',
      'admin-pending': 'Draft',
      invoiceUploaded: 'Invoice Uploaded'
    };
    return statusData[status];
  }

  get IsPartLoadOrderType() {
    return this.orderData.type === this.orderType.partLoadOffice || this.orderData.type === this.orderType.partLoadDoor;
  }

  newOrderItem(){
    return this.fb.group({
      weight: ['', Validators.required],
      pickUps: this.fb.array([]),
      deliveries: this.fb.array([]),
      products: this.fb.array([this.newProduct()]),
      notes: [''],
      _id: [],
      isweightEdit: [false],
      itemAmount: [''],
      itemWeight: [''],
    })
  }

  newProduct(product: any = {}): FormGroup {
    console.log('product::', product);
    
    return this.fb.group({
      product:[product.product || null],
      weight: [product.weight || ''],
      quantity: [product.quantity || ''],
      subItem: [product.subItem || null]
    });
  }

  newPickUp(pickUp: any = {}): FormGroup {
      let isComapny = false
      if(this.selectedAddressType == 'office-address') {
        isComapny = true
      }
    return this.fb.group({
      contactName: [pickUp.contactName || ''],
      contactNumber: [pickUp.contactNumber || ''],
      companyName: [pickUp.companyName || ''],
      addressId: [pickUp.addressId || ''],
      pincode: [pickUp.pincode || ''],
      city: [pickUp.city || ''],
      locality: [pickUp.locality || ''],
      buildingName: [pickUp.buildingName || ''],
      landmark: [pickUp.landmark || ''],
      type: [pickUp.type || ''],
      _id: [pickUp._id || ''],
      isCompany: [isComapny]
    });
  }

  newDelivery(delivery: any = {}): FormGroup {
    return this.fb.group({
      contactName: [delivery.contactName || ''],
      contactNumber: [delivery.contactNumber || ''],
      companyName: [delivery.companyName || ''],
      addressId: [delivery.addressId || ''],
      pincode: [delivery.pincode || ''],
      city: [delivery.city || ''],
      locality: [delivery.locality || ''],
      buildingName: [delivery.buildingName || ''],
      landmark: [delivery.landmark || ''],
      type: [delivery.type || ''],
      _id: [delivery._id || ''],
      userId: [delivery.userId || ''],
      isCompany: [delivery.isCompany || false]
    });
  }

  get orderItems(): FormArray {
    return this.orderForm.get('items') as FormArray;
  }

  getItemFormGroup(index: number): FormGroup {
    return this.orderItems.at(index) as FormGroup;
  }
  
  getPickUpLocations(index: number): FormArray {
    return this.orderItems.at(index).get('pickUps') as FormArray;
  }

  getExistingAddressId(id: string) {
    return this.allExistingSelectedLocaiton.some((e: any)=> e.addressId === id)
  }
  
  getDeliveryLocations(index: number): FormArray {
    return this.orderItems.at(index).get('deliveries') as FormArray;
  }
  
  getItemsProducts(index: number): FormArray {
    return this.orderItems.at(index).get('products') as FormArray;
  }

  addOrderItem(){
    return this.orderItems.push(this.newOrderItem());
  }

  addedProduct: any = [];
  addProduct(index: number, i: number){
    if(i != -1) {
      this.addedProduct.push(i);
    }
    return this.getItemsProducts(index).push(this.newProduct({}));
  }
   
  removeProduct(productIndex: number,index: number){
    return this.getItemsProducts(productIndex).removeAt(index);
  }

  // removePickLocation(pickupIndex: number) {
  //   if (this.pickUpLocations.length > 1) {
  //     this.pickUpLocations.removeAt(pickupIndex);
  //   }
  // }

  // removeDeliveryLocation(deliveryIndex: number) {
  //   if (this.deliveryLocations.length > 1) {
  //     this.deliveryLocations.removeAt(deliveryIndex);
  //   }
  // }
  getOrderDetails(id: string) {
    this.orderService.getOrderDetail(id).subscribe({
      next: (resp) => {
        this.orderData = resp.data; 
        this.allExistingSelectedLocaiton = []
        this.orderData.items.forEach((item: any) => {
          const { pickUps, deliveries } = item;
          this.allExistingSelectedLocaiton.push(...pickUps, ...deliveries);
        });
        this.getAddresses(this.orderData);
        this.getTruckList();
        this.patchOrderEstimate(resp.data);
        this.patchOrderItems(this.orderData.items)
        this.getUniqPickUpLocation(resp.data);
        this.orderData.orderDate = this.formatDate(this.orderData.orderDate);
        this.orderForm.patchValue(resp.data.orderDetailId); // path the order data
        this.orderForm.get('orderAmount')?.patchValue(resp.data.orderAmount); // patch the orderAmount
        this.orderEstimateForm
          .get('estimate')
          ?.setValue(this.orderData.estimate); // patch the order estimate
        this.orderTruckDetailForm.patchValue(resp.data); // Patch truck detail form
        this.orderInvoiceForm.patchValue(resp.data.orderDetailId);
        this.dispatchOrderForm.patchValue(resp.data.orderDetailId);
        // if (this.orderData.orderStatus == 'requested') {
        //   this.orderEstimateForm.get('isEdit')?.setValue(true);
        // } else if (this.orderData.orderStatus == 'confirmed') {
        //   this.orderTruckDetailForm.get('isEdit')?.setValue(true);
        // } else if (
        //   this.orderData.orderStatus == 'truck-assigned' &&
        //   !this.orderData.orderDetailId.invoiceDetail
        // ) {
        //   // this.orderInvoiceForm.get('isEdit')?.setValue(true);
        // }
        this.loading = false
      },
    });
  }

  getAddresses(orderData: any) {
    const userParams = { userId: this.orderData.orderBy._id, isCompany: false }
    const companyParms = { isCompany: true }
    this.orderService
      .getUserAddresses(userParams)
      .subscribe((res: any) => {
        //update _id to addressId
        this.userAddresses = res.data.docs;
        this.userAddresses = res.data.docs.map((address: any) => {
          return {
            ...address,
            addressId: address._id
          };
        });
        this.orderService
      .getUserAddresses(companyParms)
      .subscribe((res: any) => {
        //update _id to addressId
        this.companyAddresses = res.data.docs;
        this.companyAddresses = res.data.docs.map((address: any) => {
          return {
            ...address,
            addressId: address._id
          };
        });
        if(orderData.type === this.orderType.partLoadOffice){
          this.addresses = this.companyAddresses;
          this.deliveryAddresses = this.companyAddresses
        } else {
          this.addresses = this.userAddresses
          this.deliveryAddresses = this.userAddresses
        }
        // console.log(this.deliveryAddresses, 'deliveryAddresses');
        // console.log(this.addresses, 'addresses');
        // console.log(this.userAddresses, 'userAddresses');
      });
      
      });
  }

  onAddressTypeChange(selectedValue: string): void {
    // Update the addresses based on the selected option
    this.deliveryAddresses =
      selectedValue === 'office-address'
        ? this.companyAddresses
        : this.userAddresses;
  }
  getTruckList() {
    this.orderService
      .getAllTruck()
      .subscribe((res: any) => {
        this.trucksList = res.data;
      });
  }

  formatDate(date: string) {
    const formattedDate = moment(date).utcOffset('+05:30');
    return formattedDate.format('hh:mm A DD MMM YYYY');
  }

  patchOrderEstimate(data: any) {
    let charges = this.charge;
    if (data.otherCharges?.length > 0) charges = data.otherCharges;
    charges.forEach((charge: Charge) => {
      this.otherCharges.push(this.addCharge(charge));
    });
    this.orderEstimateForm.patchValue(data);
  }

  patchOrderItems(items: any){
      const itemsFormArray = this.orderForm.get('items') as FormArray;
      itemsFormArray.clear(); // Clear existing items
    
      items.forEach((item: any) => {
        const itemFormGroup = this.newOrderItem();
    
        // Patch products
        const productsFormArray = itemFormGroup.get('products') as FormArray;
        productsFormArray.clear()
        item.products.forEach((product: any) => {
          productsFormArray.push(this.newProduct(product));
        });
    
        // Patch pickUps
        const pickUpsFormArray = itemFormGroup.get('pickUps') as FormArray;
        item.pickUps.forEach((pickUp: any) => {
          pickUpsFormArray.push(this.newPickUp(pickUp));
        });
    
        // Patch deliveries
        const deliveriesFormArray = itemFormGroup.get('deliveries') as FormArray;
        item.deliveries.forEach((delivery: any) => {
          deliveriesFormArray.push(this.newDelivery(delivery));
        });
      
        // Patch other fields
        itemFormGroup.patchValue({
          weight: item.weight,
          notes: item.notes,
          _id: item._id,
          isweightEdit: item.isweightEdit,
          itemAmount: item.itemAmount,
          itemWeight: item.itemWeight,
        });
        itemsFormArray.push(itemFormGroup);
        this.orderForm.patchValue(items);
      });
  }

  compareFn(item: any, selected: any) {
    return item._id === selected._id;
  }

  openEditForm(elementType: string, elementIndex = -1) {
    if (elementType == 'pickup') {
      // const toggelVisibity = !this.pickUpLocations
      //   .at(elementIndex)
      //   .get('visible')?.value;
      // this.pickUpLocations
      //   .at(elementIndex)
      //   .get('visible')
      //   ?.setValue(toggelVisibity);
    } else if (elementType == 'deliver') {
      // const toggelVisibity = !this.deliveryLocations
      //   .at(elementIndex)
      //   .get('visible')?.value;
      // this.deliveryLocations
      //   .at(elementIndex)
      //   .get('visible')
      //   ?.setValue(toggelVisibity);
    } else if (elementType == 'estimate') {
      const toggelVisibity = !this.orderEstimateForm.get('isEdit')?.value;
      this.orderEstimateForm.get('isEdit')?.setValue(toggelVisibity);
    } else if (elementType == 'truck-assign') {
      console.log(
        'Hello in this truck assign',
        this.orderTruckDetailForm.get('isEdit')?.value
      );
      const toggelVisibity = !this.orderTruckDetailForm.get('isEdit')?.value;
      this.orderTruckDetailForm.get('isEdit')?.setValue(toggelVisibity);
    } else if (elementType == 'invoice-upload') {
      const toggelVisibity = !this.orderInvoiceForm.get('isEdit')?.value;
      this.orderInvoiceForm.get('isEdit')?.setValue(toggelVisibity);
    } else if (elementType == 'dispatch-upload') {
      const toggelVisibity = !this.dispatchOrderForm.get('isEdit')?.value;
      this.dispatchOrderForm.get('isEdit')?.setValue(toggelVisibity);
    } else if (elementType == 'weight-edit') {
      const toggelVisibity = !this.orderForm.get('isweightEdit')?.value;
      this.orderInvoiceForm.get('isweightEdit')?.setValue(toggelVisibity);
    }
  }

  onFileChange(event: any, attachmentType: string) {
    const reader = new FileReader();
    if (event.target.files.length > 0) {
      const [file] = event.target.files;
      const fileExt = file.type;

      if (file.size > this.maxUplodaSize * 1000000) {
        event.target.value = '';
        this.displayErrMessage.file = `File size should be less than ${this.maxUplodaSize} MB`;
        return;
      }

      if (!this.supportedMediaType.includes(fileExt)) {
        event.target.value = '';
        this.displayErrMessage.file = `Invalid file format.`;
        return;
      }

      reader.onload = () => {
        if (attachmentType == 'lrAttachment') {
          this.orderTruckDetailForm?.patchValue({
            truckDetail: {
              lrAttachment: {
                file: reader.result,
                name: file.name,
                size: file.size,
              },
            },
          });
        } else if (attachmentType == 'invoice') {
          this.orderInvoiceForm?.patchValue({
            invoiceDetail: {
              file: reader.result,
              name: file.name,
              size: file.size,
            },
          });
        } else if (attachmentType == 'dispatchOrder') {
          this.dispatchOrderForm?.patchValue({
            dispatchDetail: {
              attachment: {
                file: reader.result,
                name: file.name,
                size: file.size,
             }
            },
          });
        }
      };
      reader.readAsDataURL(file);
    }
  }

  fileDownload(filePath: string, fileName: string) {
    this.orderService.downloadAttachment(filePath, fileName);
  }

  isFieldValid(field: string, form: FormGroup | any) {
    return !form?.get(field)?.valid && form?.get(field)?.touched;
  }

  cancelOrder(modalContent: any, orderStateForm: FormGroup, orderState: string) {
   const options = {
    fullscreen: false,
    size: 'md',
    centered: true,
    animation: true,
    windowClass: `bst-modal`,
  };
  this.modalRef = this.modalService.open(CommonModalComponent, options);
  this.modalRef.componentInstance.title = 'Cancel Order';
  this.modalRef.componentInstance.saveButtonText = 'Yes';
  this.modalRef.componentInstance.cancelButtonText = 'No';
  this.modalRef.componentInstance.modalContent = modalContent;
  this.modalRef.componentInstance.submitEvent.subscribe(() => {
    this.orderStateSubmit(orderStateForm, orderState);
    this.modalRef.close();
  });
  }

  orderStateSubmit(orderStateForm: FormGroup, orderState: string) {
    let req = orderStateForm.getRawValue()
    if(this.orderData.state.code === 'invoiceUploaded'){
      const { orderPaid } = req
      req.orderDelivered = false
      if(!orderPaid) {
        req = {
          orderDelivered: true
        }
      } else {
        req.orderDelivered = true
      }
    } else if (orderState === 'deliver') {
      req.orderDelivered = true
    }
    
    
    if (orderStateForm.invalid) {
      this.genericValidator.validateAllFormFields(orderStateForm);
      return;
    }
    this.submitForm(
      this.orderService.updateOrderState(
        req,
        this.id,
        orderState
      )
    );
  }

  onSubmit() {
    if (this.orderForm.invalid) {
      this.genericValidator.validateAllFormFields(this.orderForm);
      return;
    }

    const orderFormValue = this.common.removeEmptyObjectsForm(
      this.orderForm.getRawValue()
    );

    this.orderService
      .updateOrderData(orderFormValue, this.id ?? '', 'full-load')
      .subscribe({
        next: (resp: any) => {
          this.common.navigateByUrl('order');
          this.common.popToast('success', 'Success', resp.message);
        },
      });
  }
  isPageRefresh = true;

  async submitForm(...args: any) {
    return new Promise((resolve) => {
      this.loader = true;
      this.vehicleAssignIndex = args[1];
      args[0].subscribe((response: any) => {
        if(this.isPageRefresh) {
          location.reload();
        }
        this.isPageRefresh = true;
        this.orderData = response.data;
        this.orderData.orderDate = this.formatDate(response.orderDate);
        this.allExistingSelectedLocaiton = []
        this.orderData.items.forEach((item: any) => {
          const { pickUps, deliveries } = item;
          this.allExistingSelectedLocaiton.push(...pickUps, ...deliveries);
        });
        this.loader = false;
        this.common.popToast('success', 'Success', response.message);
        // this.common.navigateByUrl('order');
        this.getUniqPickUpLocation(response.data);
        this.editTruckDetailsMode = false;
        this.editDispatchOrderMode = false;
        this.editEstimateMode = false;
        resolve({status:true, response});
      });
    })
  }

  //FULL LOAD

  //Other charges form

  get otherCharges(): FormArray {
    return this.orderEstimateForm.get('otherCharges') as FormArray;
  }

  addCharge(charge: Charge) {
    return this.fb.group({
      label: [charge.label, Validators.required],
      charge: [charge.charge, Validators.required],
      isEdit: [charge.isEdit],
      isDiscount: [charge.isDiscount]
    });
  }

  addNewCharge() {
    this.otherCharges.push(this.addCharge({ label: '', charge: 0, isEdit: true, isDiscount: false }));
  }

  //Add other charges
  open(modalContent: any, editMode = false, index = -1) {
    if (!editMode) {
      this.chargeForm.reset();
    } else {
      const chargeData = this.orderEstimateForm.value.otherCharges[index];
      this.chargeForm.patchValue({
        label: chargeData?.label,
        charge: chargeData?.charge,
        isEdit: true,
        isDiscount: false
      });
    }
    const options = {
      fullscreen: false,
      size: 'sm',
      centered: true,
      animation: true,
      windowClass: `bst-modal`,
    };
    this.modalRef = this.modalService.open(CommonModalComponent, options);
    this.modalRef.componentInstance.title = editMode
      ? 'Edit Charge'
      : 'Add Charge';
    this.modalRef.componentInstance.modalContent = modalContent;
    this.modalRef.componentInstance.modalForm = this.orderEstimateForm;
    this.modalRef.componentInstance.submitEvent.subscribe(() => {
      this.saveCharges(editMode, index)
    });
  }

  saveCharges(editMode: boolean, index: number) {
    this.chargeForm.get('isEdit')?.setValue(true)
    this.chargeForm.get('isDiscount')?.setValue(false)
    if(this.chargeForm.invalid) return
    if (editMode && index > -1) {
      this.otherCharges.at(index).patchValue(this.chargeForm.value);
    } else {
      console.log(this.chargeForm)
      this.otherCharges.push(this.fb.group(this.chargeForm.value));
    }
    this.submitEstimate();
  }

  removeCharge(index: number) {
    this.otherCharges.removeAt(index);
    this.submitEstimate();
  }

  calculateTotalEstimate(): number {
    const estimate = this.orderEstimateForm.value.estimate;
    const weight = this.orderData?.totalWeight;
    const amount = this.orderData?.productAmount;
    const charges = this.orderEstimateForm.value.otherCharges
    
    const chargesWithoutDiscount = charges
    .filter((charge: any) => !charge.isDiscount) // Filter out non-discount charges
    .reduce((acc: number, curr: any) => acc + curr.charge, 0); // Sum up the non-discount charges
    
    // Calculate the sum of all charges where `isDiscount` is true
    const discountCharges = charges
    .filter((charge: any) => charge.isDiscount) // Filter out discount charges
    .reduce((acc: number, curr: any) => acc + curr.charge, 0); // Sum up the discount charges
    
    // const otherCharges = this.orderEstimateForm.value.otherCharges.reduce(
    //   (a: number, b: { charge: number }) => a + b.charge,
    //   0
    // );
    const productCharges = this.IsPartLoadOrderType ? amount: estimate* weight
    return productCharges + chargesWithoutDiscount - discountCharges || 0;
  }

  submitEstimate() {
    this.orderEstimateForm.value.amount = this.calculateTotalEstimate();
    if(this.orderData?.state?.code === 'rejected') {
      this.submitForm(
        this.orderService.addCounterEstimate(this.orderEstimateForm.value, this.id)
      );
    } else {
      this.submitForm(
        this.orderService.addEstimate(this.orderEstimateForm.value, this.id)
      );
    }
    // if(this.modalRef) {
      this.modalRef.close();
    // }
    this.editEstimateMode = false;
  }

  async getSelectedTruck(truckId: string) {
    return await this.trucksList.find((e: { _id: string; })=>e._id === truckId);
  }
  getSubItem(item:any) {
    const productId = item._id ? item._id: item.productId
    const product = this.productData.find((e: { _id: string; })=>e._id === productId)
    return product ? product.subItems: [];
  }

  async changeTruck(e:any) {
    if(e) {
      this.selectedTruck = await this.getSelectedTruck(e)
    } else {
      this.selectedTruck = undefined
    }
  }

  // Truck Details
  async addTruckDetails() {
    if (this.orderTruckDetailForm.invalid) {
      this.genericValidator.validateAllFormFields(this.orderTruckDetailForm);
      return;
    }
    const requestBody = this.orderTruckDetailForm.getRawValue();
    const { availableLimit, totalLimit, truckNumber, usedLimit } = await this.getSelectedTruck(requestBody.truckDetail.truckId)
    requestBody.truckDetail.totalLimit = totalLimit
    requestBody.truckDetail.availableLimit = availableLimit
    requestBody.truckDetail.truckNo = truckNumber
    requestBody.truckDetail.usedLimit = usedLimit
    this.submitForm(
      this.orderService.addTruckDetails(
        requestBody,
        this.id
      )
    );
  }

  // Update Order Loded
  async updateOrderLoaded() {
    const requestBody  = {
      isLoaded: true
    };
    this.submitForm(
      this.orderService.loadedTruck(
        requestBody,
        this.id
      )
    );
  }

  //Assign Vehicle
  get pickUpVehicle(): FormArray {
    return this.assignVehicleForm.get('pickUpVehicle') as FormArray;
  }

  newPickupVehicle() {
    return this.fb.group({
      _id: [''],
      companyName: [''],
      vehicleNumber: [''],
      vehicleType: ['', Validators.required],
      arrivalTime: [''],
      isEdit: [true],
      showVehicleNumber: [false],
    });
  }

  addPickupVehicle() {
    this.pickUpVehicle.push(this.newPickupVehicle());
  }

  getUniqPickUpLocation(orderData: any) {
    const allPickUps = orderData.items.flatMap(
      (order: { pickUps: any }) => order.pickUps
    );
    const uniquePickUps = allPickUps.filter(
      (pickup: any, index: number, self: any) =>
        index === self.findIndex((p: any) => p.addressId === pickup.addressId)
    );
    // const uniquePickUpswithEdit = uniquePickUps.map((pickup: any) => ({
    //   ...pickup,
    //   editMode: false,
    // }));
    this.pickUpVehicle.clear();
    uniquePickUps.forEach((pickup: any) => {
      const pickupVehicleForm = this.newPickupVehicle();
      pickupVehicleForm.patchValue({
        _id: pickup._id,
        companyName: pickup.companyName,
        vehicleNumber: pickup.vehicleDetail?.vehicleNumber || '',
        vehicleType: 'main',
        arrivalTime: pickup.vehicleDetail?.arrivalTime || '',
        isEdit: pickup.vehicleDetail?.vehicleNumber ? false : true,
      });
      this.pickUpVehicle.push(pickupVehicleForm);
    });
  }

  showVehicleNumber(index: number): void {
    if (this.pickUpVehicle.at(index).get('vehicleType')?.value === 'other') {
      this.pickUpVehicle.at(index).get('showVehicleNumber')?.setValue(true);
    } else {
      this.pickUpVehicle.at(index).get('showVehicleNumber')?.setValue(false);
    }
  }

  assignVehicle(index: number) {
    let vehicleNumber;
    let selectedVehical= 'other';
    const pickupId = this.pickUpVehicle.at(index).get('_id')?.value;
    const vehicleType = this.pickUpVehicle.at(index).get('vehicleType')?.value;
    if (vehicleType === 'other') {
      vehicleNumber = this.pickUpVehicle.at(index).get('vehicleNumber')?.value;
    } else {
      vehicleNumber = this.orderData.truckDetail.truckNo;
      selectedVehical = 'main';
    }
    this.submitForm(
      this.orderService.assignVehicle(this.id, pickupId, {
        vehicleNumber: vehicleNumber, selectedVehical,
      }),
      index
    );
  }

  editPickUpDetails(pickupdata: any) {
    pickupdata.editMode = true;
  }

  getDateTimeofStatus(status: string): string | undefined {
    const dispatchedObject = this.orderData?.orderHistory?.find(
      (item: any) => item.status === status
    );
    let dateTime : string | undefined;
    if(dispatchedObject != undefined) {
      dateTime = this.formatDate(dispatchedObject?.updatedAt)
    }
    return dateTime;
  }

  //Create Order

  isAddressInPickups(addressId: string): boolean {
    // Loop through items to check if the address is already in pickups
    for (const item of this.orderItems.value) {
      if (item.pickUps && item.pickUps.some((pickUp: any) => pickUp.addressId === addressId)) {
        return true;
      }
    }
    return false;
  }

  selectedLocation: any = {}
  selectAddress(address: any, key:string){
    this.selectedAddress = address;
    this.selectedLocation[key] = this.selectedAddress; 
  }

  selectTab(index: number,tab: number, callFrom: string, saveData = false){
    if(!saveData) {
      if((tab == 2 && this.selectedLocation.pickup != undefined)) {
        this.selectedAddress = this.selectedLocation.pickup
      } else if((tab == 3 && this.selectedLocation.deliver != undefined)) {
        this.selectedAddress = this.selectedLocation.deliver
      }
    }
    if(saveData){
      const activeDataTab = tab -1;
      if((tab == 2 && this.selectedLocation.pickup == undefined) || (tab == 3 && this.selectedLocation.deliver == undefined)) {this.common.popToast('error', 'Error', 'Please select address!'); return}
      switch (activeDataTab) {
        case 1:
          if(!this.getPickUpLocations(index).length) {
            this.getPickUpLocations(index).push(this.newPickUp(this.selectedAddress));
          } else {
            this.getPickUpLocations(index).removeAt(0);
            this.getPickUpLocations(index).push(this.newPickUp(this.selectedAddress));
          }
          break;
        case 2:
          if(!this.getDeliveryLocations(index).length) {
            this.getDeliveryLocations(index).push(this.newDelivery(this.selectedAddress));
          } else { 
            this.getDeliveryLocations(index).removeAt(0);
            this.getDeliveryLocations(index).push(this.newDelivery(this.selectedAddress));
          }
          break;
      }
      this.selectedAddress = ''
    }
    if(callFrom === 'next') {
      this.activeTab = this.IsPartLoadOrderType && this.allExistingSelectedLocaiton.length ? 3 : tab;
    } else if(callFrom === 'previous') {
      this.activeTab = this.IsPartLoadOrderType && this.allExistingSelectedLocaiton.length ? 1 : tab;
    }
  }

  onProductChange(product: any, itemIndex: number, productIndex: number) {
    const products = this.getItemsProducts(itemIndex);
    products.at(productIndex).get('subItem')?.reset(null);
    // const selectedProduct = products.at(productIndex).get('product')?.value;
    // const selectedSubProduct = products.at(productIndex).get('subItem')?.value;
    // Set the subItems array based on the selected product
    // if (selectedSubProduct && selectedSubProduct.length > 0) {
    //   products.at(productIndex).get('subItem')?.enable();
    // } else {
    //   products.at(productIndex).get('subItem')?.disable();
    // }
  }

  calculateTotalWeight(index: number): number {
    return this.getItemsProducts(index).value.reduce((total: any, product: any) => parseInt(total) + parseInt(product.weight), 0);
    if(!this.IsPartLoadOrderType) {
      return this.getItemsProducts(index).value.reduce((total: any, product: any) => parseInt(total) + parseInt(product.weight), 0);
    }else {
      let totalAmount = 0;
      this.getItemFormGroup(index).value.products.forEach((product: any) => {
      const productPrice = this.orderData.type === 'part-load-door'? product.product.price : product.product.bstOfficePrice || 0;
      const productWeight = product.weight || 0;
      
      // Calculate amount based on formula
      const calculatedAmount = (productPrice / 1000) * productWeight;
      
      // Add calculated amount to totalAmount
      totalAmount += calculatedAmount;
    });
    return totalAmount
    }
  }

  calculateTotalAmount(index: number){
    let totalAmount = 0;
      this.getItemFormGroup(index).value.products.forEach((product: any) => {
      let singleProduct = product.product
      if(product.subItem) {
        singleProduct = product.subItem
      }
      const productPrice = this.orderData.type === 'part-load-door'? singleProduct.price : singleProduct.bstOfficePrice || 0;
      const productWeight = product.weight || 0;
      
      // Calculate amount based on formula
      const calculatedAmount = (productPrice / 1000) * productWeight;
      
      // Add calculated amount to totalAmount
      totalAmount += calculatedAmount;
    });
    
    return totalAmount
  }

  async createItem(index: number){
      const req = this.calculatePartLoadEstimate(index)
      console.log('req::', req);
      
      this.isPageRefresh = false
      if(this.IsPartLoadOrderType) {
        req.item.deliveries = this.orderData.items[0].deliveries
      }
      await this.submitForm(
        this.orderService.createOrderItem(this.id, req)
      );     
    this.activeTab = 1
    this.submitEstimate();
    // this.orderService.createOrderItem(this.id, this.orderForm.getRawValue()).subscribe((res)=>{
    //   console.log(res)
    // })
  }

  itemsTotalWeight(){
    // return this.orderItems.value.reduce((total: any, item: any) => parseInt(total) + parseInt(item.itemAmount), 0);
    return this.orderItems.value.reduce((total: any, item: any) => parseInt(total) + parseInt(item.itemWeight), 0);
  }

  calculatePartLoadEstimate(index: number){
    
    this.orderItems.at(index).get('itemWeight')?.setValue(this.calculateTotalWeight(index));
    let data;
    //Storing totalAmount for part load
    if(this.IsPartLoadOrderType) {
      this.orderItems.at(index).get('itemAmount')?.setValue(this.calculateTotalAmount(index));
      const orderItems = this.orderItems.value;
      const tempoCharge = this.orderData.type === this.orderType.partLoadDoor? this.appDetails.doorDeliveyTempoCharge : this.appDetails.officeDeliveyTempoCharge 
      const minWeight = this.orderData.type === this.orderType.partLoadDoor? this.appDetails.doorDeliveryMinimumWeight : this.appDetails.officeDeliveryMinimumWeight
      let totalWeight = this.itemsTotalWeight()
      const maxFreePickUps = Math.trunc(totalWeight/minWeight);
      const minProductValue = this.findMinimumProductPrice(orderItems)
      let minOrderFee, freePickups = orderItems.length     
      //extra charge if totalweight < minWeight for door delivery
      if(minWeight > totalWeight){
        const differenceWeight = minWeight - totalWeight
        minOrderFee = differenceWeight * (minProductValue/1000)
        totalWeight = differenceWeight + totalWeight
      }

      if(orderItems.length > maxFreePickUps){
        freePickups = maxFreePickUps
      }
      let orderItemsLength = orderItems.length
      if(this.orderData.type === this.orderType.partLoadOffice) {
        orderItemsLength = 0
          this.orderItems.value.forEach(async (e: any) => {
            if(e.pickUps[0].type !== 'BST Office') {
              console.log('noo bst');
              
              orderItemsLength = orderItemsLength + 1;
            }
          });
      }
      
      const charges = [
        {
          label: `Tempo fee (${orderItemsLength} Pickup)`,
          charge: parseInt(tempoCharge)*orderItemsLength,
          isDiscount: false,
          isEdit: false
        }
      ];
      if(freePickups > 0) {
        charges.push({
          label: "Discount",
          charge: freePickups *1000,
          isDiscount: true,
          isEdit: false
        })
      }
      if (this.orderData.type === this.orderType.partLoadDoor && minOrderFee) {
        charges.push({
          label: "Min order value",
          charge: minOrderFee,
          isDiscount: false,
          isEdit: false
        });
        charges.push({
          label: "Discount",
          charge: 1000,
          isDiscount: true,
          isEdit: false
        })
      }
      data = {
        item: this.orderItems.at(index).value,
        otherCharges: charges
      }
      this.otherCharges.clear();
      charges.forEach((charge: Charge) => {
        this.otherCharges.push(this.addCharge(charge));
      });
    }
    else {
       data = {
        item: this.orderItems.at(index).value
      }
    }

    // console.log(this.orderItems.at(index).value)
    this.orderData.items = this.orderItems.value
    return data;

      // this.orderService.addEstimate({otherCharges: charges}, this.id).subscribe((res)=>{
      //   console.log(res)
      // })

    // console.log('Tempo charge', tempoCharge*1000)
    // console.log('Min order value', minOrderFee)
    // console.log('Discount', freePickups * 1000)
    // console.log('Total', (orderItems.length*1000) + minOrderFee - (freePickups*1000))
  }

  singleItemTotalWeight(products: any){
    return products.reduce((acc: number, product: any) => acc + product.weight, 0);
  }

 findMinimumProductPrice(items: any[]): number {
    let minPrice = Infinity; // Start with a high value
  
    items.forEach(item => {
      item.products.forEach((productEntry: any) => {
        let singleProduct = productEntry.product
        if(productEntry.subItem) {
          singleProduct = productEntry.subItem
        }
        const productPrice = singleProduct.price;
  
        if (productPrice < minPrice) {
          minPrice = productPrice; // Update minPrice if a lower price is found
        }
      });
    });
  
    return minPrice === Infinity ? 1 : minPrice; // Return null if no products are found
  }
  oldProduct: any;
  openEditItem(modalContent: any, index: number){
    this.activeItemIndex = index
    const itemData = this.orderData.items[index];
    // const index2 = this.orderItems.value.findIndex((x: any) => x._id == event.id);
    // const index2 = this.orderItems.value.findIndex((x: any) => x._id == event.id);
    // this.oldProduct = this.getItemFormGroup(index2).value;
    this.oldProduct = this.getItemFormGroup(index).value;
    console.log('this.oldProduct::', this.oldProduct);
    
    const options = {
      fullscreen: false,
      size: 'lg',
      centered: true,
      animation: true,
      windowClass: `bst-modal`,
    };
    this.modalRef = this.modalService.open(CommonModalComponent, options);
    this.modalRef.componentInstance.title = 'Edit Product Details'
    this.modalRef.componentInstance.modalContent = modalContent;
    this.modalRef.componentInstance.modalForm = this.getItemFormGroup(index);
    this.modalRef.componentInstance.submitEvent.subscribe(() => {
      this.updateItem(itemData._id, index);
    });
    this.modalRef.componentInstance.closeEvent.subscribe(() => {
      this.getItemFormGroup(index).patchValue(this.oldProduct);
      this.addedProduct.map((e: any) => {
        this.removeProduct(index,e)
      });
      this.addedProduct = [];
    });
  }

  sendNotification() {
    const data: any = {
      item: this.orderData.items[0],
      sendOrderUpdate: true
    };
     this.submitForm(
      this.orderService.updateOrderItem(this.id, data.item._id, data)
    );
  }
  onCaptureEvent(event: any) {
    if (event && event.cmd) {
      if (event.cmd == 'edit') {
      const index = this.orderItems.value.findIndex((x: any) => x._id == event.id);
        this.openEditItem(this.modal, index);
      }
    }
  }

  async updateItem(id: string, index: number){
    this.isPageRefresh = false
    //  this.orderItems.at(index).get('itemWeight')?.setValue(this.calculateTotalWeight(index));
    await this.submitForm(
      this.orderService.updateOrderItem(this.id, id, this.calculatePartLoadEstimate(index))
    );
    this.modalRef.close()
    this.submitEstimate();
  }

  async deleteOrderItem(event: any){
    if(event.delete){
      this.isPageRefresh = false
      const index = this.orderItems.value.findIndex((x: any) => x._id == event.id);
      this.orderItems.removeAt(index);
      await this.submitForm(
        this.orderService.deleteOrderItem(this.id, event.id)
      );
      this.calculatePartLoadEstimate(index)
      this.submitEstimate();
    }
  }

  // deleteOrderItem(id: string, index: number){
  //   this.orderItems.removeAt(index);
  //   this.submitForm(
  //     this.orderService.deleteOrderItem(this.id, id)
  //   );
  // }

  placeOrder(){
    this.submitForm(
      this.orderService.placeOrder(this.id));
    // this.orderService.placeOrder(this.id).subscribe((res)=>{
    //   this.orderData = res
    // })
  }

  //Change/Add address
  openAddress(data:any) {
    this.selectedAddressData = data;
    if(this.selectedAddressData.type === 'pickup' && this.orderData.type === this.orderType.partLoadOffice) this.deliveryAddresses = this.companyAddresses
    this.toggleModal('show');
  }

  toggleModal(action: string) {
    const offcanvasElement = document.getElementById('offcanvasExample');
    if (action === 'show') {
      const offcanvas = new bootstrap.Offcanvas(offcanvasElement);
      offcanvas.show();
    } else {
      const offcanvas = bootstrap.Offcanvas.getInstance(offcanvasElement);
      offcanvas.hide();
    }
  }

  selectAddressData(data: any) {
    this.selectedAddressData.address = data;
  }

  saveAddressData(data: any) {
    const order = this.findOrderById(this.orderData.items, data.order);
    if (!order) return;
    let formArray: FormArray;
    let formvalue;
    this.isPageRefresh = false
    if (data.type === 'pickup') {
      formArray = this.getPickUpLocations(data.index)
      formArray.clear()
      formArray.push(this.fb.control(data.address));
      formvalue = this.orderItems.at(data.index).getRawValue()
    } else {
      formArray = this.getDeliveryLocations(data.index);
      if(data.type === 'add'){
        formArray.push(this.fb.control(data.address));
        formvalue = this.orderItems.at(data.index).getRawValue()
      }else {
        order.deliveries[data.deliveryIndex] = data.address
        formvalue = order
        // formArray.at(data.deliveryIndex).setValue(this.fb.control(data.address))
        // order.deliveries = order.deliveries.map((delivery: any) => 
        //   delivery._id === data.address._id ? data.address : delivery
        // );
      }
    }

    // this.updateFormArray(formArray, order[data.type === 'pickup' ? 'pickUps' : 'deliveries']);
    // this.orderForm.patchValue(order);
    this.submitForm(
      this.orderService.updateOrderItem(this.id, data.order, {item: formvalue})
    );
    const index = this.orderItems.value.findIndex((x: any) => x._id == data.order);
    console.log('index::', index, data.order, this.orderItems.value);
    
    this.calculatePartLoadEstimate(index)
    this.submitEstimate();
    setTimeout(()=>{
      this.toggleModal('hide');
    },100)
  }

  updateFormArray(formArray: FormArray, data: any[]) {
    formArray.clear();
    data.forEach(item => {
      formArray.push(this.fb.group(item));
    });
  }

  findOrderById(array: any, orderId: string) {
    return array.find((order: any) => order._id === orderId);
  }

  removeAddress(itemIndex: number, deliveryIndex: number, orderId: string){
    this.getDeliveryLocations(itemIndex).removeAt(deliveryIndex);
    this.submitForm(
      this.orderService.updateOrderItem(this.id, orderId, {item: this.orderItems.at(itemIndex).getRawValue()})
    );
    return
    // const order = this.findOrderById(this.orderData.items, orderItemId);
    // if (order) {
    //   order.deliveries = order.deliveries.filter((delivery: any) => delivery._id !== deliverId);
    //   // this.updateFormArray(this.deliveryLocations, order.deliveries);
    //   this.orderForm.patchValue(order);
    //   this.submitForm(
    //     // this.orderService.updateOrderItem(this.id, orderItemId, {deliveries: this.deliveryLocations.getRawValue()})
    //   );
    //   console.log(this.orderForm.value);
    // }
  }
}
